.page-resources-event {

	$spacing: px-to-em(96px);

	.resources-content {
		padding: $spacing 1em;
		background-color: $color-white-white-smoke;


		+ .next-section {
			padding-top: 0;
		}
	}

	.contacts-section {
		margin-top: px-to-em(60px);
	}

	.related-cards {
		padding: $spacing;
		background-color: $color-white;
	}

	@include bp-max(1050px){
		.contacts-section {
			.professional-tile-grid {
				text-align: left;
			}

			.professional-tile {
				margin-left: 0;
			}
		}
	}

	@include bp-max($bp-between){
		$spacing: px-to-em(48px);

		.resources-content {
			padding: $spacing 1em;
		}

		.related-cards {
			padding: $spacing 0;
		}
	}
}
