.stage-info-overlay {
	$btn-dev-stage-size: 8em;
	$vertical-margin: 3em;
	$mobile-view-bp: 650px;

	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba($color-black, .8);
	top: 0;
	left: 0;
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s $transition-duration-primary,
		z-index 0s $transition-duration-primary,
		opacity $transition-duration-primary;

	&.show-overlay {
		z-index: 1000;
		visibility: visible;
		opacity: 1;
		transition: visibility 0.01s, opacity $transition-duration-primary 0.01s;
	}

	.stage-info-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		width: 90%;
		max-width: px-to-em(1050px);
		background-color: $color-white;
		padding: 4em 2em;
	}

	.content-header {
		font-size: px-to-em(36px);
		text-transform: uppercase;
		color: $color-black;
		text-align: center;
		letter-spacing: 1px;
	}

	.content-intro {
		margin-top: 1.5em;
	}

	.btn-close-overlay {
		color: $color-white;
		position: absolute;
		bottom: calc(100% + 1em);
		right: 0;

		&:after {
			@include font-icons($icon-cross);
			font-size: 1.5em;
		}
	}

	@include bp-min($mobile-view-bp){

		.current-stage {
			display: none;
		}

		.dev-stages {
			display: flex;
			justify-content: space-between;
			position: relative;
			align-items: center;
			margin-top: $vertical-margin;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: $btn-dev-stage-size * 0.5;
				width: 100%;
				height: 1px;
				background-color: $color-gray-gainsboro;
			}
		}

		.dev-stage {
			text-align: center;
			width: $btn-dev-stage-size;

			&.is-selected {

				.btn-dev-stage {
					background-color: $color-latham-red;
					color: $color-white;
				}

				.dev-stage-journey-full {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		.btn-dev-stage {
			width: $btn-dev-stage-size;
			height: $btn-dev-stage-size;
			border-radius: $btn-dev-stage-size;
			border: 1px solid $color-gray-gainsboro;
			background-color: $color-white;
			position: relative;
			z-index: 1;
			text-transform: uppercase;
			color: $color-latham-red;
			letter-spacing: .5px;
			transition: color $transition-duration-primary, background-color $transition-duration-primary;

			@include font-sans-serif-bold;

			&:hover {
				color: $color-white;
				background-color: $color-latham-red;
			}
		}

		.dev-stage-journey-full {
			width: 150%;
			position: relative;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			text-align: center;
			margin-top: $vertical-margin;
			visibility: hidden;
			opacity: 0;
			line-height: 1.25;

			> * {
				margin-top: .5em;
			}
		}

		.dev-stage-journeys-mobile {
			display: none;
		}
	}

	@include bp-max(850px){
		$stage-size-small: 6em;

		.dev-stages {
			&:before {
				top: $stage-size-small * 0.5;
			}
		}

		.dev-stage {
			width: $stage-size-small
		}

		.btn-dev-stage {
			width: $stage-size-small;
			height: $stage-size-small;
		}
	}

	@include bp-max($mobile-view-bp){
		$padding: .25em .75em;
		$spacing: 2em;

		.content-header {
			font-size: px-to-em(24px);
		}

		.content-intro {
			margin-top: $spacing;
		}

		.stage-info-content {
			padding: $spacing;
		}

		.content-stage-select {
			position: relative;
		}

		.current-stage {
			border: 1px solid $color-latham-red;
			background-color: $color-white;
			padding: $padding;
			text-transform: uppercase;
			color: $color-latham-red;

			@include font-sans-serif-semibold;

			&:after {
				@include font-icons($icon-angle-down);
				float: right;
			}
		}

		.content-stage-select {
			margin-top: $spacing;
		}

		.dev-stages {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			border: 1px solid $color-latham-red;
			border-top: none;
			background: $color-white;
			cursor: pointer;
			display: none;

			&:before {
				content: none;
			}

			&.show-dropdown {
				display: block;
			}
		}

		.dev-stage {
			width: auto;
			padding: $padding;

			&.is-selected {
				display: none;
			}
		}

		.btn-dev-stage {
			width: auto;
			height: auto;
		}

		.dev-stage-journey-full {
			display: none;
		}

		.dev-stage-journeys-mobile {
			margin-top: $spacing;
		}

		.dev-stage-journey-mobile {
			display: none;

			&.is-selected {
				display: block;
			}

			> * {
				margin-top: .25em;
			}
		}
	}

	@include bp-max(374px){
		$spacing: 1em;

		.content-intro {
			margin-top: $spacing;
		}

		.stage-info-content {
			padding: $spacing;
		}

		.content-stage-select {
			margin-top: $spacing;
		}

		.dev-stage-journeys-mobile {
			margin-top: $spacing;
		}
	}
}
