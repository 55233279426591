.homepage-logo-animation {
	$image-nudge: 2.5%;	//Nudge Latham logo into the skew angle
	$animation: transform $homepage-edge-transition-duration;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	white-space: nowrap;
	width: calc(100vw - #{$page-edge-size * 2});
	padding: 1em;
	text-align: center;
	display: flex;
	justify-content: center;

	.latham-container {
		transition: $animation;
		position: relative;
		z-index: 1;
		transform: translate3d($image-nudge * -1, 0, 0);
		max-width: 55.65%;

		&.logo-init-position {
			transform: translate3d(40%, 0, 0);

			.skew-container,
			.skew-reverse {
				transform: skew(0);
			}

			.logo-latham {
				transform: translate3d(0, 0, 0);
			}
		}
	}

	// Use a skew-container and skew-reverse to create the skew cutoff effect on the 'M'
	.skew-container {
		transform: skew($latham-drive-skew-angle);
		overflow: hidden;
		padding-left: 1em;
		transition: $animation;
		-webkit-backface-visibility: hidden;
	}

	.skew-reverse {
		transform: skew($latham-drive-skew-angle * -1);
		transition: $animation;
		-webkit-backface-visibility: hidden;
	}

	.logo-latham {
		width: px-to-em(522px);
		transform: translate3d($image-nudge, 0, 0);
		transition: $animation;
		display: block;
		max-width: 100%;
	}

	// Placeholder is hidden to take up spacing since real logo is positioned absolutely
	.drive-container-placeholder {
		visibility: hidden;
		max-width: 40.1%;
	}

	// Move the container onto the right edge of Latham
	.drive-container {
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 100%;
		transform: translate3d(-2.75%, 0, 0);
		z-index: -1;
		width: 69%;
	}

	// Slide drive logo in from the left side of container
	.logo-drive {
		transition: $animation;
		transform: translate3d(0%, 0, 0);
		width: px-to-em(375px);
		position: relative;
		z-index: 0;
		display: block;
		max-width: 100%;

		&.logo-init-position {
			transform: translate3d(-100%, 0, 0);
		}
	}
}
