.search-result {
	$results-spacing: 2em;

	&:not(:first-child){
		margin-top: 2em;
	}

	.search-result-link {
		@include font-sans-serif(20px);

		b {
			@include font-sans-serif-bold;
		}
	}

	.search-result-brief {
		margin-top: .5em;

		@include font-sans-serif(18px);

		b, highlight {
			@include font-sans-serif-bold;
		}
	}

	.search-result-url {
		margin-top: .5em;
		display: inline-block;

		@include font-sans-serif-light-italic;

		b {
			font-weight: bold;
		}
	}


	@include bp-max($bp-site-search-grid){
		.search-result-link {
			font-size: px-to-em(18px);
		}

		.search-result-brief {
			font-size: 1em;
		}
	}
}
