.resource-share-tools {
	$side-padding: 20px;

	text-align: center;

	.share-tools-label {
		$font-size: 18px;

		text-transform: uppercase;
		text-align: center;
		color: $color-gray-trout;
		font-size: px-to-em($font-size);
		border-bottom: 1px solid $color-gray-trout;
		padding-bottom: .5em;

		@include font-sans-serif-semibold;
	}

	.share-links {
		display: flex;
		justify-content: center;
		margin-top: .5em;
	}

	.share-link-container {
		margin-right: 1em;

		&:last-of-type {
			margin-right: 0;
		}
	}

	.share-link {
		color: $color-latham-red;
		font-size: px-to-em(24px);

		&:hover {
			color: $color-gray-trout;
		}

		&:before {
			position: relative;
		}
	}

	.share-twitter {
		&:before {
			@include font-icons($icon-twitter-x);
			top: 1px;
		}
	}

	.share-linkedin {
		&:before {
			@include font-icons($icon-linkedin);
		}
	}

	.share-email {
		&:before {
			@include font-icons($icon-envelope);
		}
	}

	.btn-call-to-action {
		margin-top: 1.5em;
		width: 100%;
		padding: 1em;
		position: relative;

		&:first-of-type {
			margin-top: 3.5em;
		}
	}

	.download-link,
	.itunes-link,
	.google-play-link {
		$spacing: 3em;

		padding-right: $spacing;

		&:after {
			font-size: 1em;
			position: absolute;
			right: $spacing * 0.5;
			top: 50%;
			transform: translate3d(50%, -50%, 0);
		}
	}

	.download-link {
		&:after {
			@include font-icons($icon-download);
		}	
	}

	.itunes-link {
		&:after {
			@include font-icons($icon-music);
		}	
	}

	.google-play-link {
		&:after {
			@include font-icons($icon-play);
		}	
	}
}
