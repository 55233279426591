.search-filters {
	position: relative;
	background-color: $color-white;
	z-index: 1;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-white;
		z-index: 1;
	}

	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $color-gray-gainsboro;
		z-index: 1;
	}

	.search-filter-types {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.search-filter-prompt {
		color: $color-black;
		letter-spacing: .75px;
		padding-right: 1em;
		background-color: $color-white;
		position: relative;
		z-index: 2;
	}

	.search-filter-type {
		.search-filter-slide-down {
			position: absolute;
			top: calc(100% + 1px);
			width: 100%;
			left: 0;
			transition: transform $transition-duration-primary;
			transform: translate3d(0, -101%, 0);
			z-index: -1;

			&.is-selected {
				transform: translate3d(0, 0, 0);
				z-index: 0;
			}
		}
	}

	@include bp-max(550px){
		.search-filter-types {
			flex-wrap: wrap;
		}

		.search-filter-prompt {
			$spacing: .5em;

			width: 100%;
			left: $spacing;
			margin-bottom: $spacing;
		}
	}
}
