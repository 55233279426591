.page-homepage {
	$btn-size: 6em;

	.homepage-fullpage {
		.section {
			position: relative;
		}
	}

	#fp-nav.right {
		right: $page-edge-size * 1.5;
	}

	.section-container {
		position: relative;
	}

	.stages-intro {
		$duration: .5s;

		max-width: px-to-em(700px);
		padding: 0 $page-edge-size * 1.5;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		position: relative;
		opacity: 1;
		transition: opacity $duration,
			transform $duration;
		transform: translate3d(0, px-to-em(50px), 0);
		z-index: 1;

		&.initial-animate {
			transform: translate3d(0, 100%, 0);
			opacity: 0;
		}

		&.fade-out {
			transition: opacity .3s;
			opacity: 0;
		}
	}

	.intro-brief {
		font-size: 1.25em;
	}

	.stages-prompt {
		margin-top: .5em;
		font-size: 1.5em;
	}

	.stages-panel {
		background-color: $color-gray-gainsboro;

		.section-container {
			padding-top: $page-edge-size + px-to-em($global-nav-height);
			padding-bottom: $page-edge-size + px-to-em($homepage-footer-height);
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.stages-mobile-container {
		padding: 0 $page-edge-size-mobile;
		flex-grow: 1;
		z-index: -100;
		position: absolute;
		width: 100%;

		.stage-topics-container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 .25em;
		}

		.topic-link-container {
			width: 50%;
			padding: .75em 0;
			text-align: center;

			@include font-sans-serif;

			a {
				color: $color-latham-red;
			}
		}
	}

	.stages-topics-tabs {
		display: none;
	}

	.last-slide-footer {
		position: fixed;
		width: 100%;
		bottom: $page-edge-size;
		left: 0;
		visibility: hidden;
		opacity: 0;
		transition: opacity $transition-duration-primary 0.01s, visibility 0s $transition-duration-primary;
	}

	&.fp-viewing-1 {
		.last-slide-footer {
			visibility: visible;
			opacity: 1;
			transition: opacity $transition-duration-primary * 2 ease-in, visibility $transition-duration-primary;
		}

		.stages-intro {
			display: block;
		}
	}

	@include bp-max($bp-between) {

		.intro-brief,
		.stages-prompt {
			line-height: 1.25;
		}

		.intro-brief {
			font-size: 1em;
		}

		.stages-prompt {
			font-size: 1.25em;
		}
	}

	@include bp-min($bp-mobile) {

		@include bp-max($bp-between){
			.stages-intro {
				transform: translate3d(0, 25px, 0);
			}

			#fp-nav.right {
				right: $page-edge-size-between;
			}

			.last-slide-footer {
				bottom: $page-edge-size-between;
			}
		}

		@include bp-max-height(850px){
			@include bp-min($bp-between) {
				.stages-intro {
					transform: translate3d(0, 75px, 0);
				}
			}
		}

		@include bp-max-height($bp-between){
			.stages-intro {
				transform: translate3d(0, 50px, 0);
			}

			.intro-brief,
			.stages-prompt {
				line-height: 1.25;
			}

			.intro-brief {
				font-size: 1em;
			}

			.stages-prompt {
				font-size: px-to-em(18px);
			}
		}

		@include bp-max-height(650px){

			#development-stages {
				max-width: px-to-em(800px);
			}
		}

		@include bp-max-height(550px) {

			#development-stages {
				max-width: px-to-em(650px);
			}
		}

		@include bp-max-height(500px) {

			#development-stages {
				max-width: px-to-em(600px);
			}
		}

		@include bp-max-height(450px) {

			#development-stages {
				max-width: px-to-em(550px);
			}
		}
	}

	//
	// Footer control
	//


	@include bp-max-height(550px){
		.stages-panel {
			.section-container {
				padding-bottom: $page-edge-size;
			}
		}

		.last-slide-footer {
			.global-footer {
				display: none;
			}
		}
	}

	@include bp-max(600px) {
		.last-slide-footer {
			.global-footer {
				display: none;
			}
		}
	}

	//
	// Mobile Portrait
	//
	@include bp-max($bp-mobile){
		#fp-nav.right {
			right: $page-edge-size-mobile * 0.5;
		}

		.stages-panel {
			.section-container {
				padding-top: $page-edge-size-mobile + px-to-em($global-nav-height-mobile);
				padding-bottom: $page-edge-size-mobile;
			}
		}

		.stages-mobile-container {
			position: relative;
			z-index: 1;

			.topic-link-container {
				padding: .25em .5em;
				line-height: 1.1;

				a {
					font-size: px-to-em(14px);
				}
			}
		}

		.stages-intro {
			$spacing: 2em;

			padding-left: $spacing;
			padding-right: $spacing;
			padding-bottom: .5em;
			z-index: 1;
			transform: translate3d(0,0,0);
		}

		.intro-brief {
			font-size: 1em;
		}
	}

	@include bp-max($bp-mobile-small){
		.stages-panel {
			.section-container {
				padding-top: $page-edge-size-mobile + px-to-em($global-nav-height-mobile) - .5em;
				padding-bottom: $page-edge-size-mobile;
			}
		}

		.stages-intro {
			padding-top: 10vh;
		}

		.stages-mobile-container {
			position: absolute;
			z-index: -1;
		}

		.stages-topics-tabs {
			display: flex;
		}


		.intro-brief {
			font-size: px-to-em(14px);
		}

		.stages-prompt {
			font-size: 1em;
		}
	}

	//
	// Mobile Landscape
	//
	@include bp-max-height($bp-mobile){

		.stages-panel {
			.section-container {
				padding-top: $page-edge-size-mobile + px-to-em($global-nav-height-mobile-landscape);
				padding-bottom: $page-edge-size-mobile;
			}
		}

		.stages-prompt {
			font-size: 1em;
		}

		.intro-brief {
			font-size: px-to-em(14px);
		}

		#fp-nav.right {
			right: $page-edge-size-mobile * 0.5;
		}

		#development-stages {
			&.no-intro {
				max-width: px-to-em(575px);
			}
		}

		.last-slide-footer {
			bottom: $page-edge-size-mobile;
		}
	}

	@include bp-max-height(375px) {

		.stages-intro {
			transform: translate3d(0, 0, 0);
			padding-top: 10vh;
		}

		#development-stages {
			display: none;
		}

		.stages-topics-tabs {
			display: flex;
		}
	}


	@include bp-max-height($bp-mobile-small){
		.stages-intro {
			padding-top: 0;
		}
	}
}
