body {
	padding: $page-edge-size;

	&.no-edge {
		padding: 0;
	}
}

.width-wrapper {
	$padding: 1em;

	max-width: px-to-em(635px) + $padding * 2;
	padding-left: $padding;
	padding-right: $padding;
	margin-left: auto;
	margin-right: auto;
}

.width-wrapper-wider {
	$padding: 1em;

	max-width: px-to-em(735px) + $padding * 2;
	padding-left: $padding;
	padding-right: $padding;
	margin-left: auto;
	margin-right: auto;
}

.width-wrapper-1000 {
	$padding: 1em;

	max-width: px-to-em(1000px) + $padding * 2;
	padding-left: $padding;
	padding-right: $padding;
	margin-left: auto;
	margin-right: auto;
}

.has-page-hero {
	position: relative;
	margin-top: px-to-em($page-hero-height);
}

.has-page-hero-no-jump-links {
	position: relative;
	margin-top: px-to-em($page-hero-height-no-jump-links);	
}

@include bp-max($bp-between){
	body {
		padding: $page-edge-size-between;
	}
}

@include bp-max($bp-mobile){
	body {
		padding: $page-edge-size-mobile;
	}
}
