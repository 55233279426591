.search-criteria-list {
	$spacing: .5em;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: $spacing * -1;

	.search-criteria-container {

		margin-right: $spacing;
		margin-bottom: $spacing;

		&:last-of-type {
			margin-right: 0;
		}
	}
}
