@use "sass:math";

.circular-item {
	position: relative;
	display: block;

	.item-link {
		border-radius: 50%;

		&:hover {
			.item-background {
				&:before {
					width: 110%;
				}
			}
		}
	}

	.item-background {
		$size: px-to-em(300px);

		width: $size;
		height: $size;
		border-radius: $size;
		background-color: $color-gray-shuttle-gray;
		background-position: 50% 50%;
		position: relative;
		border: 3px solid $color-latham-red;
		overflow: hidden;
		transform: translate3d(0,0,0);

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0) skew($latham-drive-skew-angle);
			width: 0%;
			height: 110%;
			background-color: $color-latham-red;
			transition: width $transition-duration-primary;
		}
	}

	.item-content {
		$size: math.div(1, $sqrt-2) * 100%;

		height: $size;
		width: $size;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		color: $color-white;
		text-align: center;
	}

	.item-copy {
		padding: px-to-em(18px) 0;
		position: relative;
		margin-top: 1em;

		&:before,
		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			left: 50%;
			height: 1px;
			width: 60%;
			transform: translate3d(-50%, 0, 0);
			background-color: $color-white;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}
	}

	.item-header {
		text-transform: uppercase;
		@include font-sans-serif-bold;
	}

	.item-brief {
		line-height: 1.25;
		margin-top: 1em;
	}
}
