.stage-select {
	.stage-select-prompt {
		display: none;
	}

	.current-selection {
		display: none;
	}

	.stage-select-option {
		$font-size: 24px;

		color: $color-latham-red;
		letter-spacing: .5px;
		text-transform: uppercase;
		background-color: $color-white;
		padding: px-to-em(16px, $font-size);
		border-bottom: 1px solid $color-white-white-smoke;
		cursor: pointer;
		transition: color $transition-duration-primary,
			background-color $transition-duration-primary;

		@include font-sans-serif-light($font-size);

		&.is-selected {
			background-color: $color-latham-red;
			color: $color-white;
			@include font-sans-serif-bold;
		}

		&:hover {
			background-color: $color-latham-red;
			color: $color-white;
		}
	}

	@include bp-max($bp-between){
		.stage-select-option {
			font-size: 20px;
		}
	}

	@include bp-max(600px){
		$spacing: .5em 1em;

		position: relative;

		.stage-select-prompt {
			display: block;
			margin-bottom: .5em;
		}

		.current-selection {
			display: block;
			border: 1px solid $color-latham-red;
			background-color: $color-white;
			padding: $spacing;
			text-transform: uppercase;
			color: $color-latham-red;
			letter-spacing: .5px;
			cursor: pointer;

			&:after {
				float: right;
				transition: transform $transition-duration-primary;
				@include font-icons($icon-angle-down, 18px);
			}
		}

		.stage-select-options {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			border: 1px solid $color-latham-red;
			border-top: none;
			display: none;

		}

		.stage-select-option {
			font-size: 1em;
			border: none;
			padding: $spacing;

			&.is-selected {
				display: none;
			}
		}

		&.show-filter-options {
			.current-selection {
				&:after {
					transform: rotateX(180deg);
				}
			}

			.stage-select-options {
				display: block
			}
		}
	}
}
