.btn-call-to-action {
	display: inline-block;
	color: $color-latham-red;
	line-height: px-to-em(28px);
	padding: 1em 2em;
	border: 1px solid $color-latham-red;
	letter-spacing: .75px;
	position: relative;
	transition: color $transition-duration-primary,
		background-color 0s;
	z-index: 0;
	overflow: hidden;
	transform: translate3d(0,0,0);

	&:before {
		content: '';
		display: inline-block;
		width: 0;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: $color-latham-red;
		transition: width $transition-primary;
		z-index: -1;
		transform: translate3d(-50%, -50%, 0)
			skew($latham-drive-skew-angle);
		-webkit-backface-visibility: hidden;
	}

	&:after {
		@include font-icons($icon-angle-right);
		font-size: 1.5em;
		position: relative;
		top: 4px;
		margin-left: 1em;
	}

	&:hover {
		color: $color-white;
		background-color: $color-latham-red;
		transition: color $transition-duration-primary,
			background-color 0s $transition-duration-primary * .75;

		&:before {
			width: 120%;
		}
	}

	&.external-link {
		&:after {
			@include font-icons($icon-external-link);
			font-size: 1em;
			top: 3px;
		}
	}

	&.no-icon {
		&:after {
			content: none;
		}
	}

	&.icon-team {
		background-color: $color-white-white-smoke;

		&:after {
			@include font-icons($icon-users);
			font-size: 1.25em;
			top: 2px;
		}
	}

	&.icon-plane {
		background-color: $color-white-white-smoke;

		&:after {
			@include font-icons($icon-paper-plane);
			font-size: 1.25em;
			top: 0;
		}
	}

	&.icon-pdf {
		background-color: $color-white-white-smoke;

		&:after {
			@include font-icons($icon-file);
			font-size: 1.25em;
			top: 1px;
		}
	}
}

.btn-load-more {
	display: inline-block;
	font-size: px-to-em(18px);
	letter-spacing: 1.25px;
	color: $color-gray-aluminum;

	&:after {
		margin-left: 1em;
		@include font-icons($icon-plus);
	}

	&:hover {
		color: $color-gray-trout;
	}
}
