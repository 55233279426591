.content-card-list {
	$margin: 1em;

	justify-content: center;
	max-width: px-to-em(1264px);
	margin: 0 auto $margin * -1;

	.center-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: inherit;
		margin-right: $margin * -1;
	}

	.content-card {
		margin-right: $margin;
		margin-bottom: $margin;
	}
}
