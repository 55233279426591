.book-of-jargon-term {
	color: $color-latham-red;
	cursor: pointer;
	position: relative;

	.mce-content-body & {
		cursor: auto;
		background-color: $color-gray-gainsboro;
	}
}
