.page-site-search {

	.main {
		padding: px-to-em(96px) 0;
		background-color: $color-white;
	}

	.page-hero {
		padding-bottom: 0;
	}

	.section-container {
		background-color: $color-white;
	}

	@include bp-max($bp-between){
		.main {
			padding: px-to-em(48px) 0;
		}
	}
}
