.professional-tile-grid {
	$gutter: 2.5em;

	display: flex;
	flex-wrap: wrap;

	margin-bottom: $gutter * -1;
	padding-right: $professional-tile-grid-padding;
	padding-left: $professional-tile-grid-padding;
	justify-content: space-between;

	.professional-tile {

		width: calc(50% - #{$gutter*0.5});
		margin-bottom: $gutter;
	}

	&.large-tile-size {
		@include bp-max(1280px){
			display: block;

			.professional-tile {
				width: auto;
				max-width: px-to-em(675px);
				margin-left: auto;
				margin-right: auto;
			}
		}

		@include bp-max(650px){
			.professional-tile {
				max-width: px-to-em(475px);

				@include professional-tile-standard-size-styles;
			}
		}
	}

	&.standard-tile-size {
		@include bp-max(1050px){
			display: block;

			.professional-tile {
				width: auto;
				max-width: 475px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	@include bp-max(550px) {
		text-align: center;

		.professional-tile {
			display: inline-block;
			max-width: none;
		}

		.profile-image-container {
			float: none;
			margin-right: 0;
			margin-bottom: 1em;
			display: inline-block;
		}

		.profile-content {
			text-align: left;
		}
	}
}
