.form-textarea {
	$side-padding: 16px;

	border: 1px solid $color-latham-red;
	border-radius: $form-input-border-radius;
	background-color: $color-white;

	.field-text-area {
		width: 100%;
		padding: .75em px-to-em($side-padding);
		color: $color-black;
		letter-spacing: 1px;
		border-radius: inherit;
		border: none;
		resize: none;

		@include placeholder {
			color: $color-black;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
}
