@use "sass:math";

@mixin professional-tile-standard-size-styles {
	$image-size: px-to-em(195px);

	.profile-image-link {
		border-radius: $image-size;
		width: $image-size;
		height: $image-size;
	}

	.professional-name {
		font-size: px-to-em(24px);
	}

	.professional-role {
		margin-top: .1em;
	}

	.contact-information {
		padding: .25em 0;
		margin-top: .5em;
	}

	.contact-number {
		padding: .1em 1em .1em 2em;
	}
}

.professional-tile {
	$duration: $transition-duration-primary;

	display: block;

	@include clearfix;

	.profile-image-container {
		float: left;
		margin-right: 1em;
	}

	.profile-image-link {
		border: 1px solid $color-latham-red;
		background-size: cover;
		background-position: 12% 20%; // Random percentages that work with most crops
		display: block;
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			border-radius: inherit;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-black, .6);
			opacity: 0;
			transition: opacity $duration;
		}

		&:hover {
			&:before {
				opacity: 1;
			}

			.hover-prompt {
				line-height: 3em;
				opacity: 1;
				transition: line-height $duration math.div($duration, 3),
					opacity $duration;
			}
		}
	}

	.hover-prompt {
		$border-style: 1px solid $color-white;

		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		text-transform: uppercase;
		color: $color-white;
		letter-spacing: 2px;
		font-size: px-to-em(22px);
		white-space: nowrap;
		border-top: $border-style;
		border-bottom: $border-style;
		line-height: 0em;
		overflow: hidden;
		opacity: 0;
		transition: line-height $duration,
			opacity $duration * 2;
	}

	.profile-content {
		white-space: nowrap;
	}

	.professional-name {
		line-height: 1;
		text-transform: uppercase;
		color: $color-latham-red;
		letter-spacing: 1px;
		white-space: normal;
	}

	.professional-role {
		color: $color-black;
		font-size: px-to-em(22px);
		letter-spacing: 1px;
	}

	.contact-information {
		$border-style: 1px solid $color-gray-shady-lady;

		border-top: $border-style;
		border-bottom: $border-style;
		display: inline-flex;
		flex-direction: column;
	}

	.contact-numbers {
		display: table;
	}

	.contact-row {
		display: table-row;
	}

	.contact-location {
		display: table-cell;
		letter-spacing: .75px;
		line-height: 1;
	}

	.contact-number {
		display: table-cell;
		letter-spacing: .75px;

	}

	.email-link {
		color: $color-latham-red;
		letter-spacing: .75px
	}

	@include professional-tile-standard-size-styles;

	&.large-tile {
		$image-size: px-to-em(300px);

		.profile-image-link {
			border-radius: $image-size;
			width: $image-size;
			height: $image-size;
		}

		.professional-name {
			font-size: px-to-em(32px);
		}

		.professional-role {
			margin-top: .5em;
		}

		.contact-information {
			padding: 1em 0;
			margin-top: 1.5em;
		}

		.contact-number {
			padding: .2em 1em .2em 2em;
		}

		.email-link {
			margin-top: .25em;
		}
	}
}
