@use "sass:math";

.page-hero {
	$jump-link-size: 14px;
	$jump-link-line-height: 1;
	$jump-link-padding: 16px;
	$jump-links-total-height: $jump-link-size * $jump-link-line-height + $jump-link-padding * 2;

	$animation-duration: .5s;

	position: fixed;
	width: calc(100% - #{$page-edge-size * 2});
	left: $page-edge-size;
	top: $page-edge-size;
	padding-bottom: px-to-em($jump-links-total-height * 0.5);

	&.bg-color-black {
		background-color: $color-white-white-smoke;
	}

	&.init-animation {
		.background-image-container {
			&:after {
				opacity: 1;
			}
		}

		.page-hero-content {
			transform: translate3d(0, 3em, 0);
			opacity: 0;
		}
	}

	.background-image-container {
		background-color: $color-gray-nero;
		background-size: cover;
		background-position: 50% 50%;
		height: px-to-em($page-hero-height - $jump-links-total-height * 0.5);
		border-bottom: 3px solid $color-latham-red;

		position: relative;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $color-gray-nero;
			opacity: 0;
			transition: opacity $animation-duration * 2;
		}
	}

	.page-hero-content {
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: transform $animation-duration,
			opacity $animation-duration * 2;
	}

	.page-category {
		text-transform: uppercase;
		color: $color-white;
		letter-spacing: 1px;
		font-size: px-to-em(14px);
		padding: .75em;
		border: 1px solid $color-gray-aluminum;
		background-color: rgba($color-black, .5);
		margin-bottom: 3em;
	}

	.page-title {
		$font-size: 48px;

		color: $color-white;
		text-transform: uppercase;
		line-height: 1;
		max-width: px-to-em(600px, $font-size);

		@include font-sans-serif-light($font-size);		
	}

	.page-intro {
		$font-size: 24px;

		font-size: px-to-em($font-size);
		max-width: px-to-em(632px, $font-size);
		padding: 0 px-to-em(16px, $font-size);
		margin: 0 auto;
		color: $color-white-white-smoke;
		line-height: 1.25;
		margin-top: 1em;
	}

	.page-nav {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		background-color: $color-latham-red;
		display: flex;
		align-items: center;
		padding: px-to-em($jump-link-padding) px-to-em(20px);
		white-space: nowrap;
	}

	.prompt {
		color: $color-red-mona-lisa;
		font-size: px-to-em(14px);
		line-height: 1;
		margin-right: .5em;
	}

	.jump-links {
		display: flex;
		align-items: center;
	}

	.jump-link {
		$spacing: px-to-em(20px, $jump-link-size);

		font-size: px-to-em($jump-link-size);
		line-height: $jump-link-line-height;
		color: $color-white;
		text-transform: uppercase;
		text-decoration: underline;
		margin-right: $spacing;
		cursor: pointer;
		position: relative;
		transition: color $transition-duration-primary;
		white-space: normal;

		@include font-sans-serif-bold;

		&:hover {
			color: $color-gray-silver;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 50%;
			right: math.div($spacing, -2);
			transform: translate3d(50%, -50%, 0);
			width: 1px;
			height: 100%;
			background-color: $color-white-white-smoke;
			cursor: auto;
		}

		&:last-of-type {
			margin-right: 0;

			&:after {
				content: none;
			}
		}
	}

	@include bp-max($bp-between){
		width: calc(100% - #{$page-edge-size-between * 2});
		left: $page-edge-size-between;
		top: $page-edge-size-between;
	}

	@include bp-max($bp-mobile){
		width: calc(100% - #{$page-edge-size-mobile * 2});
		left: $page-edge-size-mobile;
		top: $page-edge-size-mobile;
	}

	@include bp-max-height($bp-mobile){
		.background-image-container {
			height: calc(100vh - #{$page-edge-size * 2});
		}
	}
}
