.homepage-intro-panel {

	.fullpage-background-container {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-black, .45);
		}
	}

	.fullpage-background-image {
		position: absolute;
		top: 50%;
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		transform: translate3d(-50%, -50%, 0);
	}

	.fullpage-background-video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		min-width: 100%;
		min-height: 100%;
		display: none;	// Show after Modernizr checks for videoautoplay
	}

	.intro-tagline {
		$duration: 1s;

		position: absolute;
		width: 90%;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		color: $color-white;
		font-size: px-to-em(72px);
		white-space: normal;
		padding: .25em .75em;
		transition: opacity $duration .01s, visibility .01s, transform $duration;

		@include font-sans-serif-light;

		&.tagline-init {
			opacity: 0;
			visibility: hidden;
			transform: translate3d(-50%, 100%, 0);
		}
	}

	.btn-next-section {
		$duration: 1s;

		position: absolute;
		bottom: 3em;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		transition: opacity $duration .01s, visibility .01s, transform $duration;

		&:after {
			color: $color-white;
			font-size: 3em;
			transition: color $transition-duration-primary;
			@include font-icons($icon-arrow-down);
		}

		&:hover {
			&:after {
				color: $color-latham-red;
			}
		}

		&.btn-init {
			opacity: 0;
			visibility: hidden;
			transform: translate3d(-50%, -50%, 0);
		}
	}

	@include bp-max(1100px){
		.intro-tagline {
			font-size: px-to-em(48px);			
		}
	}

	@include bp-max($bp-between - 1px) {
		.intro-tagline {
			white-space: normal;
			text-align: center;
		}
	}

	@include bp-max($bp-mobile){
		.intro-tagline {
			line-height: 1.2;
			font-size: px-to-em(40px);
			width: calc(100vw - 2em);
		}

		.btn-next-section {
			bottom: 1.5em;
		}

		.fullpage-background-video {
			opacity: 0;
		}
	}

	@include bp-max($bp-mobile - 1px) {
		.intro-tagline {
			font-size: px-to-em(36px);
		}
	}

	@include bp-max(374px){
		.intro-tagline {
			font-size: px-to-em(30px);
			width: calc(100vw - 2.5em);
		}
	}

	@include bp-max-height($bp-mobile-small){
		.intro-tagline {
			font-size: 2em;
		}

		.btn-next-section {
			bottom: $page-edge-size-mobile;

			&:after {
				font-size: 2em;
			}
		}
	}
}
