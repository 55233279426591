.book-of-jargon-dictionary {
	$spacing: 1.5em;

	margin-top: 6em;

	.alpha-filter {
		margin-top: $spacing;
	}

	.results-definition-list {
		margin-top: $spacing;
		border-top: 1px solid $color-black;
	}

	.clear-search {
		margin-top: $spacing * 2;
		text-align: center;
		position: relative;
	}

	.btn-clear-search {
		@extend %letter-nav-button-circle-style;
		color: $color-latham-red;
		transition: border-color $transition-duration-primary;

		&:after {
			@include font-icons($icon-cross);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			font-size: 1.25em;
			transition: color $transition-duration-primary;
		}

		&:hover {
			color: $color-gray-trout;
			border-color: $color-gray-trout;
		}
	}

	.letter-navigation {
		margin-top: $spacing * 2;
	}

	@include bp-max($bp-between) {
		margin-top: 4em;
	}
}
