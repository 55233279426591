.stage-visual {
	$circle-size: 14px;
	$max-label-width: 50px;
	$border-style: 1px solid $color-gray-gainsboro;

	display: flex;
	padding: 0 px-to-em($max-label-width - $circle-size)*0.5;

	.stage-visual-circle {
		flex-grow: 1;
		padding: 1.5em 0 px-to-em($circle-size);
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			width: px-to-em($circle-size);
			height: px-to-em($circle-size);
			border-radius: px-to-em($circle-size);
			border: $border-style;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:after {
			content: '';
			display: inline-block;
			width: calc(100% - #{px-to-em($circle-size)});
			height: 1px;
			background-color: $color-gray-gainsboro;
			position: absolute;
			bottom: px-to-em($circle-size)*0.5;
			left: px-to-em($circle-size);
		}

		&:last-of-type {
			flex-grow: 0;
			padding-right: px-to-em($circle-size);

			&:after {
				content: none;
			}
		}
	}

	.stage-visual-label {
		$font-size: 8px;

		font-size: px-to-em($font-size);
		line-height: 1;
		text-transform: uppercase;
		position: absolute;
		bottom: px-to-em($circle-size, $font-size) + .5em;
		left: px-to-em($circle-size, $font-size) * 0.5;
		transform: translate3d(-50%, 0, 0);
		text-align: center;
		max-width: px-to-em($max-label-width, $font-size);
		opacity: 0;

		@include font-sans-serif-bold;
	}

	@for $i from 1 through 5 {

		&.stage-#{$i} {
			.stage-visual-circle:nth-of-type(#{$i}) {
				&:before {
					background-color: $color-white;
				}

				.stage-visual-label {
					opacity: 1;
				}
			}
		}
	}

}
