//------------------------------------------
//				Variables
//------------------------------------------
$font-family-source-sans-pro: 'source-sans-pro';

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

//------------------------------------------
//				Font Assignments
//------------------------------------------

$font-family-sans-serif: $font-family-source-sans-pro, Arial;



//------------------------------------------
//				Mixins
//------------------------------------------

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
	@font-face {
		font-family: $font-family;
		src: url('#{$file-path-fonts}#{$file-path}.eot');
		src: url('#{$file-path-fonts}#{$file-path}.eot?#iefix') format('embedded-opentype'),
			 url('#{$file-path-fonts}#{$file-path}.woff') format('woff'),
			 url('#{$file-path-fonts}#{$file-path}.ttf') format('truetype'),
			 url('#{$file-path-fonts}#{$file-path}.svg##{$font-family}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}

@mixin font-sans-serif ($font-size: null, $base-size: null){
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
	font-style: normal;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

@mixin font-sans-serif-light ($font-size: null, $base-size: null){
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-light;
	font-style: normal;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

@mixin font-sans-serif-light-italic ($font-size: null, $base-size: null){
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-light;
	font-style: italic;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

@mixin font-sans-serif-semibold ($font-size: null, $base-size: null){
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-semibold;
	font-style: normal;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

@mixin font-sans-serif-bold ($font-size: null, $base-size: null){
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-bold;
	font-style: normal;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

@mixin font-size($font-size, $base-size){
	@if ($base-size == null) {
		font-size: px-to-em($font-size);
	}

	@else {
		font-size: px-to-em($font-size, $base-size);
	}
}
