.form-input-radio-buttons {
	display: flex;
	flex-wrap: wrap;

	.radio-button-container {
		&:not(:last-child){
			margin-right: 2em;
		}
	}

	.field-input-radio {
		display: none;

		&:checked {
			+ .field-label-radio {
				&:before {
					background-color: $color-latham-red;
				}
			}
		}
	}

	.field-label-radio {
		$size: 1em;

		letter-spacing: 1px;
		position: relative;
		padding-left: $size * 1.5;
		cursor: pointer;
		display: inline-block;
		line-height: 1.2;
		&:before {
			content: '';
			display: inline-block;
			width: $size;
			height: $size;
			border-radius: 50%;
			border: 1px solid $color-latham-red;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate3d(0, -50%, 0);
			transition: background-color $transition-duration-primary;
			background-color: $color-white;
		}
	}
}
