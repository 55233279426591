.page-resources-landing {
	$spacing: px-to-em(96px);

	.resource-tools {
		background-color: $color-white-white-smoke;
		padding: $spacing;
		position: relative;
		z-index: 2;

		.section-header {
			margin-top: 1em;
		}

		.circular-items-grid {
			max-width: 1000px;
			margin-top: px-to-em(64px);
		}
	}

	.sign-up {
		position: relative;
		z-index: 2;
		background-color: $color-white;
		text-align: center;
		padding: $spacing 0;

		.btn-call-to-action {
			margin-top: 3em;
		}
	}

	.resource-articles {
		padding-bottom: $spacing;
		text-align: center;
		background-color: $color-white;

		#resources-search {
			text-align: left;
		}
	}

	@include bp-max(880px){
		$spacing: px-to-em(48px);

		.resource-tools {
			padding: $spacing;
		}

		.sign-up {
			padding: $spacing 0;
		}

		.resource-articles {
			padding-bottom: $spacing;

			.btn-load-more {
				margin-top: 2em;
			}
		}
	}

	@include bp-max($bp-mobile){
		$spacing: 2em;

		.resource-tools {
			padding: $spacing;
		}

		.sign-up {
			padding: $spacing 0;
		}

		.resource-articles {
			padding-bottom: $spacing;
		}
	}
}
