.connect-section {
	$spacing: px-to-em(96px);

	background-color: $color-white;
	text-align: center;
	padding: $spacing 1em;

	.section-intro {
		text-align: center;
	}

	.btn-call-to-action {
		$spacing: 2em;

		margin: $spacing $spacing*0.5 0;

		&:first-of-type {
			margin-top: 3em;
		}
	}

	@include bp-max($bp-between){
		$spacing: px-to-em(48px);

		padding: $spacing 1em;
	}

}
