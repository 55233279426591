.page-team-detail {
	$section-spacing: px-to-em(96px);

	.profile-intro {
		padding: $section-spacing 2em;
		text-align: center;
		background-color: $color-white-white-smoke;

		.rich-text {
			text-align: left;
			margin-top: 3em;
		}

		.btn-call-to-action {
			margin-top: 4em;

			&:after {
				line-height: 1;
				margin-left: 1em;
				position: relative;
				top: 2px;
				@include font-icons($icon-external-link);
			}
		}
	}

	.related-cards {
		padding: $section-spacing 0;
	}

	@include bp-max($bp-between){
		$section-spacing-between: px-to-em(48px);

		.profile-intro {
			padding: $section-spacing-between 1em;

			.rich-text {
				margin-top: 2em
			}

			.btn-call-to-action {
				margin-top: 3em;
			}
		}

		.related-cards {
			padding: $section-spacing-between 0;
		}
	}

	@include bp-max($bp-between){
		.profile-intro {
			padding: px-to-em(24px) 1em;

			.rich-text {
				margin-top: 1em
			}

			.btn-call-to-action {
				margin-top: 2em;
			}
		}

	}

	@include bp-max(374px){
		.btn-call-to-action {
			font-size: .9em;
		}

		.next-section {
			justify-content: center;
		}
	}
}
