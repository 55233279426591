.page-team-landing {
	$spacing: px-to-em(96px);

	.practice-chairs {
		background-color: $color-white-white-smoke;
		padding: $spacing 0;

		.professional-tile-grid {
			max-width: px-to-em(1200px) + $professional-tile-grid-padding * 2;
			margin: 3em auto 0;
		}
	}

	.practice-leaders {
		padding: $spacing 0;
	}

	.accolade {
		padding: px-to-em(45px) 1em;
		background-color: $color-black;

		.rich-text {
			color: $color-white;
			text-align: center;
		}
	}

	@include bp-max($bp-between){
		$spacing: px-to-em(48px);

		.practice-chairs,
		.practice-leaders {
			padding: $spacing 0;
		}
	}
}
