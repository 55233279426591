.site-search-app {
	$vertical-spacing: 3em;
	$grid-left-width: px-to-em(190px);

	.site-search-criteria {
		margin-top: $vertical-spacing;
	}

	.search-criteria-label,
	.search-criteria-keyword {
		font-size: px-to-em(24px);
	}

	.search-criteria-keyword {
		margin-left: .25em;
		color: $color-latham-red;
		@include font-sans-serif-semibold;
	}

	.site-search-grid {
		margin-top: $vertical-spacing;
		display: flex;
		justify-content: space-between;
	}

	.grid-left {
		width: $grid-left-width;
	}

	.grid-right {
		width: calc(100% - #{$grid-left-width + 3em});
	}

	.load-more {
		text-align: center;
		margin-top: $vertical-spacing;
	}

	.btn-load-more {
		transition: color $transition-duration-primary;
	}


	@include bp-max($bp-site-search-grid){
		$mobile-spacing: 2em;

		.site-search-criteria {
			margin-top: $mobile-spacing;
		}

		.search-criteria-label,
		.search-criteria-keyword {
			font-size: px-to-em(20px);
		}

		.site-search-grid {
			flex-wrap: wrap;
			margin-top: $mobile-spacing;
		}

		.grid-left,
		.grid-right {
			width: 100%;
		}

		.current-selection {
			margin-bottom: $mobile-spacing;
		}
	}
}
