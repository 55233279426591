.page-resources-document {
	$card-spacing: px-to-em(55px);
	$spacing: px-to-em(96px);

	.resources-content {
		background-color: $color-white-white-smoke;
		padding-top: $spacing;
	}

	.resource-documents {
		background-color: $color-white-white-smoke;
		padding: $spacing 0;
	}

	.document-card-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: $card-spacing * -1;
	}

	.document-card {
		width: calc(50% - #{$card-spacing*0.5});
		margin-bottom: $card-spacing;
	}

	@include bp-max($bp-between){
		$spacing: px-to-em(48px);


		.resources-content {
			padding-top: $spacing;
		}

		.resource-documents {
			padding: $spacing 0;
		}

		.document-card {
			width: 100%;
		}
	}
}
