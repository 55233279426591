.expanding-grid {
	$border-style: 1px solid $color-gray-gainsboro;;
	$content-container-padding: 100px;
	$vertical-spacing: 2em;

	background-color: $color-white;
	text-align: left;

	.grid-items {
		display: flex;
		flex-wrap: wrap;
	}

	.grid-btn {
		width: 33.33%;
		padding: px-to-em(110px) 0;
		display: block;
		text-align: center;
		transition: box-shadow $transition-duration-primary;
		border: $border-style;
		position: relative;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 60% auto;

		&:hover {
			box-shadow: 0 5px 25px 0 rgba($color-black, 0.1);
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			transition: background-color $transition-duration-primary;
		}

		&.is-active {
			border-bottom: none;
			box-shadow: none;
		}

		&.is-inactive {
			&:after {
				background-color: rgba($color-white, .85);
			}

			&:hover {
				&:after {
					background-color: rgba($color-white, 0);
				}
			}
		}
	}

	.grid-content {
		width: 100%;
		display: none;
	}

	.grid-content-container {
		display: flex;
		align-items: middle;
		position: relative;
		border: $border-style;
		border-top: none;
		padding: px-to-em($content-container-padding) 0;
		display: none;

		&.is-selected {
			display: flex;
		}
	}

	.left-container,
	.right-container {
		width: 15%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&:before,
		&:after {
			content: '';
			flex-grow: 1;
		}

		.arrow {
			&:before {
				font-size: 1.5em;
				line-height: 1;
				color: $color-gray-aluminum;
			}
		}
	}

	.left-container {
		.arrow {
			margin-right: 1em;

			&:before {
				@include font-icons($icon-angle-left);
			}
		}
	}

	.right-container {
		.arrow {
			margin-left: 1em;

			&:before {
				@include font-icons($icon-angle-right);
			}
		}
	}

	.middle-container {
		width: 70%;
		padding: 0 px-to-em($content-container-padding);
	}

	.story-intro {
		@include font-sans-serif(24px);
	}

	.story-questions-container {
		margin-top: $vertical-spacing;
	}

	.story-question-answer {
		&:not(:first-child){
			margin-top: $vertical-spacing;
		}
	}

	.story-question {
		text-transform: uppercase;
		@include font-sans-serif-semibold;
	}

	.story-answer {
		margin-top: .5em;
		margin-left: 0;
	}

	.client-blockquote {
		margin-top: $vertical-spacing;
		margin-bottom: 0;
	}

	.client-quote {
		@include font-sans-serif(24px);

		&:before {
			content: '"';
		}

		&:after {
			content: '"';
		}
	}

	.quote-attribution {
		display: flex;
		align-items: center;
		margin-top: .5em;

		@include font-sans-serif-light-italic;

		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 1px;
			background-color: $color-gray-aluminum;
			margin-right: .5em;
		}
	}

	.prompt {
		display: block;
		text-align: center;
		color: $color-gray-aluminum;
		transition: color $transition-duration-primary;
	}

	.btn-prev-item,
	.btn-next-item {
		&:hover {
			.prompt {
				color: $color-gray-trout;
			}
		}
	}

	.image {
		vertical-align: middle;
		max-width: 60%;
	}

	.btn-close-container {
		$position: 3em;

		position: absolute;
		top: $position;
		right: $position;

		&:before {
			font-size: 2em;
			color: $color-gray-aluminum;
			@include font-icons($icon-cross);
		}
	}


	.load-more-container {
		text-align: center;
		margin-top: 3em;
	}

	.btn-load-more {
		color: $color-latham-red;
		transition: color $transition-duration-primary;

		&:hover {
			color: $color-gray-trout;
		}
	}

	@include bp-max(1000px){
		.grid-btn {
			width: 50%;
		}

		.grid-content-container {
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		.middle-container {
			width: 100%;
		}

		.left-container,
		.right-container {
			margin-top: 2em;
			order: 1;
			width: 25%;
		}
	}

	@include bp-max($bp-between) {
		$padding: px-to-em(50px);

		.grid-btn{
			padding: px-to-em(80px)
		}

		.middle-container {
			padding: 0 $padding*0.5;
		}

		.left-container,
		.right-container {
			width: 50%;
		}
	}

	@include bp-max(700px){
		.grid-btn {
			width: 100%;
		}
	}

	@include bp-max(500px) {
		.grid-content-container {
			padding: px-to-em(25px);
		}

		.middle-container {
			padding: 0;
		}

		.story-intro,
		.client-quote {
			font-size: px-to-em(20px);
		}

		.btn-close-container {
			right: 1em;
			top: auto;
			bottom: 100%;
		}
	}
}
