//------------------------------------------
//				Breakpoints
//------------------------------------------

@use "sass:math";

$bp-between: 767px;
$bp-mobile: 414px;
$bp-mobile-small: 320px;

$bp-mobile-menu: 850px;

$bp-site-search-grid: 600px;

//------------------------------------------
//              File Paths
//------------------------------------------
$file-path-images: '/images/';
$file-path-fonts: '/fonts/';

//------------------------------------------
//              Math Constants
//------------------------------------------

$sqrt-2: 1.41421356237;

//------------------------------------------
//				Transitions
//------------------------------------------

$transition-duration-primary: .3s;
$transition-ease-primary: cubic-bezier(1, 0, 0, 1);
$transition-primary: $transition-duration-primary $transition-ease-primary;

//------------------------------------------
//              z-index
//------------------------------------------
$z-index-global-nav: 1000;
$z-index-page-edges: $z-index-global-nav + 100;
$z-index-mobile-menu: $z-index-global-nav + 50;

$z-index-book-of-jargon-popup: 500;

$z-index-typeahead: 10;

//------------------------------------------
//      Component/Module Variables
//------------------------------------------

$latham-drive-skew-angle: -14deg;

$accordion-label-triangle-size: 1em;

$global-nav-height: 102px;

$homepage-edge-transition-duration: 1s;

$page-edge-size: 2.25em;
$page-edge-size-between: math.div($page-edge-size*2, 3);
$page-edge-size-mobile: 1em;

$page-hero-height: 550px;
$page-hero-height-no-jump-links: 527px;

$professional-tile-grid-padding: 1em;

$form-input-border-radius: 3px;

$global-nav-height: 78px;
$global-nav-height-mobile: 61px;
$global-nav-height-mobile-landscape: 41px;

$homepage-footer-height: 40px;
