html {
	font-size: 100%;
	box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

body {
	line-height: 1.5;
	color: $color-gray-trout;
	@include font-sans-serif;

	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input {
	border: none;
	padding: 0;
}

button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

a, a:visited {
	color: $color-latham-red;
	text-decoration: none;
	transition: color $transition-duration-primary;
}

// .no-touchevents a:hover {
// 	color: $color-links-hover;
// 	text-decoration: none;
// }

ul, ol {
	margin: 0;
	padding: 0;
}

ul {
	list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-style: normal;
	margin: 0;
	padding: 0;
}

figure {
	margin: 0;
}

p {
	margin: 0;
}

dl {
	margin: 0;
}
