.global-footer {
	display: flex;
	flex-wrap: wrap;
	background-color: $color-gray-shuttle-gray;
	padding: .5em $page-edge-size * 1.5;
	width: 100%;
	position: relative;

	.company-logo {
		flex-grow: 1;
	}

	.logo-link {
		width: px-to-em(144px);
		display: block;

		&:before {
			@include font-icons($icon-logo-footer-new);
			color: $color-white;
			font-size: 2em;
			line-height: 0;
			position: relative;
			top: 9px;
		}
	}

	.nav-links {
		display: flex;
		flex-wrap: wrap;
	}

	.social-links {
		display: flex;
	}

	.nav-link {		
		$spacing: 1em;

		text-transform: uppercase;
		padding-right: $spacing;
		margin-right: $spacing;
		position: relative;
		white-space: nowrap;

		@include font-sans-serif-semibold(12px);
		color: $color-white;

		&:hover {
			color: $color-gray-silver;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 1px;
			background-color: rgba($color-gray-catskill-white, .5);
		}
	}

	.social-link-container {
		&:last-of-type {
			.social-link {
				margin-right: 0;
			}
		}
	}

	.social-link {
		color: $color-white;
		margin-right: 1em;

		&:hover {
			color: $color-gray-silver;
		}
	}

	.social-twitter {
		&:after {
			@include font-icons($icon-twitter-x);
			position: relative;
			top: 3px;
		}
	}

	.social-linkedin {
		&:after {
			position: relative;
			top: 2px;
			@include font-icons($icon-linkedin);
		}
	}

	@include bp-max(625px){
		$padding: .5em;

		display: block;
		text-align: center;
		padding-left: $padding;
		padding-right: $padding;

		.company-logo {
			display: block;
			text-align: center;
			margin-bottom: .2em;
		}

		.logo-link {
			display: inline-block;
			width: auto;
		}

		.nav-links,
		.social-links {
			display: inline-flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	@include bp-max(375px) {
		.nav-link-container{
			&:last-of-type {
				.nav-link {
					margin-right: 0;
					padding-right: 0;

					&:after {
						content: none;
					}
				}
			}
		}

		.social-links {
			display: flex;
		}
	}
}
