.book-of-jargon-term-popup {
	$padding: 1.5em;
	$timing: .4s;

	position: absolute;
	border: 1px solid $color-latham-red;
	background-color: $color-gray-gainsboro;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s $timing * 0.5, opacity $timing * 0.5;
	z-index: $z-index-book-of-jargon-popup;
	max-width: calc(100% - #{$page-edge-size * 2});
	transform: translate3d(0,0,0);

	&.show-popup {
		visibility: visible;
		opacity: 1;
		transition: visibility .01s, opacity $timing .01s;
		padding: $padding;
	}

	.term-name {
		color: $color-black;
	}

	.term-definition {
		margin-top: .5em;
	}

	.btn-close-definition {
		position: absolute;
		top: $padding * .75;
		right: $padding * .75;
		transform: translate3d(50%, -50%, 0);
		transition: color $timing;

		&:before {
			@include font-icons($icon-cross);
			font-size: 1em;
			line-height: 1;
		}

		&:hover {
			color: $color-latham-red;
		}
	}

	@include bp-max($bp-between) {
		max-width: calc(100% - #{$page-edge-size-between * 2});
	}

	@include bp-max($bp-mobile) {
		max-width: calc(100% - #{$page-edge-size-mobile * 2});
	}
}
