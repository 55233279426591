.rich-text,
.mce-content-body  {

	.accolade-template {
		background-color: $color-black;

		.accolade-normal-style,
		.accolade-accent-style {
			color: $color-white;
			text-align: center;

			&:not(:first-child){
				margin-top: 15px;
			}
		}

		.accolade-normal-style {
			font-size: px-to-em(30px);
			text-transform: uppercase;
			letter-spacing: 5px;
		}

		.accolade-accent-style {
			line-height: 1.25;
			letter-spacing: 1px;
			@include font-sans-serif-bold(58px);
		}

		@include bp-max($bp-between) {
			.accolade-normal-style {
				font-size: px-to-em(24px);
			}

			.accolade-accent-style {
				font-size: px-to-em(40px);
			}
		}
	}
}
