.resources-grid {
	$gutter: px-to-em(100px);
	$aside-width: px-to-em(225px);
	$subsection-spacing: px-to-em(50px);

	display: flex;

	.primary-content {
		width: calc(100% - #{$aside-width + $gutter});
		margin-right: $gutter;

		> .btn-call-to-action {
			margin-top: 2em;
		}
	}

	.event-time {
		font-size: px-to-em(36px);
		margin-bottom: .75em;
		display: inline-block;
		color: $color-gray-trout;
		letter-spacing: 1px;
		line-height: 1.25;

		@include font-sans-serif-semibold;
	}

	.event-location {
		margin-bottom: 2em;
	}

	.event-location-details.rich-text {
		font-size: 1.25em;
	}

	.article-date {
		margin-bottom: 1em;
		display: inline-block;
		color: $color-black;
	}

	.advice-accordion {
		margin-top: 1.5em;
	}

	.aside-content {
		width: $aside-width;
	}

	.action-plan {
		margin-top: $subsection-spacing;
	}

	.document-generator {
		.overview-text {
			margin-top: 3em;
		}

		.btn-call-to-action {
			margin-top: 2em;
		}
	}

	@include bp-max(1000px){
		$gutter: px-to-em(50px);

		.primary-content {
			width: calc(100% - #{$aside-width + $gutter});
			margin-right: $gutter;
		}
	}

	@include bp-max(825px){
		display: block;

		.primary-content {
			width: auto;
			margin-right: 0;
		}

		.aside-content {
			margin-top: $subsection-spacing;
		}
	}
}
