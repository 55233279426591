.results-definition-list {
	$spacing: 1.5em;

	.results-term {
		color: $color-black;
		text-transform: uppercase;
		font-size: px-to-em(18px);
		padding-top: $spacing;
		border-top: 1px solid $color-gray-gainsboro;

		&:first-of-type {
			border-top: none;
		}
	}

	.results-definition {
		padding-bottom: $spacing;
		margin: 0;
	}

	.is-hidden {
		display: none;
	}

	.no-results-message {
		margin-top: 1em;
		display: block;
		color: $color-black;
	}
}
