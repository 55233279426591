//Color names - based on http://www.color-blindness.com/color-name-hue/
$color-black: #000;
$color-white: #fff;

$color-gray-dark-gray: #aaa;
$color-gray-gainsboro: #e6e6e6;
$color-gray-shuttle-gray: #636466;
$color-gray-bastille: #2f2d31;
$color-gray-woodsmoke: #303434;
$color-gray-catskill-white: #e0e5da;
$color-gray-silver: #c2c2c2;
$color-gray-trout: #53595D;
$color-gray-aluminum: #828A8F;
$color-gray-shady-lady: #979797;
$color-gray-charcoal: #4A4A4A;
$color-gray-nero: #151515;

$color-white-white-smoke: #F2F2F2;

$color-latham-red: #AC0000;
$color-red-mona-lisa: #fd8686;

//Color assignments
$color-general-copy: $color-black;
$color-links-normal: $color-black;
$color-links-hover: $color-black;
$color-copy-primary: $color-black;


$color-clickable: $color-black;
$color-clickable-hover: $color-black;
$color-article-tags: $color-black;
