.page-contact {
	$section-spacing: 2em;

	.main {
		padding: px-to-em(96px) 0;
		background-color: $color-white-white-smoke;
	}

	.contact-us-form {
	}

	.field-container {
		&:not(:first-child){
			margin-top: $section-spacing;
		}

		&.field-name {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.form-input-label {
				width: 100%;
			}

			.form-input-field {
				width: calc(50% - .5em);
			}
		}
	}

	.form-input-label {
		display: block;
		margin-bottom: .25em;
	}

	.btn-call-to-action {
		$padding: .5em;

		margin-top: $section-spacing;
		display: inline-block;
		padding-top: $padding;
		padding-bottom: $padding;
		margin-right: 1em;
		margin-bottom: 1em;

		&:after {
			content: none;
		}
	}

	.message-success,
	.message-error {
		color: $color-latham-red;
		font-size: 1.25em;

		@include font-sans-serif-semibold;
	}

	.privacy-information {
		margin-top: $section-spacing * 2;
	}

	.privacy-header {
		@include font-sans-serif-semibold;
	}

	.privacy-details {
		margin-top: .5em;
		font-size: px-to-em(14px);
	}

	@include bp-max($bp-between){
		.main {
			padding: px-to-em(48px) 0;
		}

		.privacy-information {
			margin-top: $section-spacing;
		}
	}

	@include bp-max($bp-mobile){
		.field-container {
			&.field-name {
				.form-input-field {
					width: 100%;

					&:not(:first-of-type){
						margin-top: 1em;
					}
				}
			}
		}
	}
}
