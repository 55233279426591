.search-criteria {

	$font-size: 18px;

	letter-spacing: 1.25px;
	font-size: px-to-em(18px);
	padding: px-to-em(8px, $font-size) px-to-em(14px, $font-size);
	color: $color-gray-aluminum;
	border: 1px solid $color-gray-gainsboro;
	transition: color $transition-duration-primary, border-color $transition-duration-primary;

	&:after {
		font-size: .85em;
		margin-left: 1em;

		@include font-icons($icon-cross);
	}

	&.clear-criteria {
		&:after {
			content: none;
		}
	}

	&:hover {
		color: $color-gray-trout;
		border-color: $color-gray-trout;
	}

	@include bp-max($bp-between){
		font-size: 1em;
	}

	@include bp-max($bp-mobile){
		font-size: px-to-em(14px);
	}
}
