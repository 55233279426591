.search-input-typeahead {
	$border-style: 1px solid $color-latham-red;
	$border-radius: 5em;
	$side-padding: 2em;

	.input-container {
		border: $border-style;
		border-radius: $border-radius;
		position: relative;
		display: flex;
		background-color: $color-white;
		padding-right: 1em;
		z-index: $z-index-typeahead;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: -1px;
			width: calc(100% + 2px);
			height: 50%;
			background-color: $color-white;
			border-left: $border-style;
			border-right: $border-style;
			display: none;
		}
	}

	.search-input {
		width: 100%;
		padding: .75em $side-padding;
		color: $color-black;
		letter-spacing: 1px;
		border-radius: inherit;
		position: relative;

		@include placeholder {
			color: $color-black;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}

	.btn-search-input-submit,
	.btn-search-clear {
		color: $color-latham-red;
		font-size: 1.25em;
		line-height: 1;
		transition: color $transition-duration-primary;
		position: relative;

		&:hover {
			color: $color-gray-trout;
		}
	}

	.btn-search-input-submit {
		&:after {
			@include font-icons($icon-search);
		}
	}

	.btn-search-clear {
		font-size: 1.5em;
		margin-right: .25em;

		&:after {
			@include font-icons($icon-cross);
		}
	}

	.typeahead-results {
		position: absolute;
		background-color: $color-white;
		top: 100%;
		left: -1px;
		width: calc(100% + 2px);
		border: $border-style;
		border-bottom: none;
		border-top: 1px solid $color-gray-gainsboro;
		display: none;
	}

	.typeahead-result {
		padding: .25em $side-padding;
		background-color: inherit;
		transition: background-color $transition-duration-primary;
		cursor: pointer;

		&:hover {
			background-color: $color-white-white-smoke;
		}

		&:first-of-type {
			padding-top: .5em;
		}

		&:last-of-type {
			position: relative;

			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 100%;
				left: -1px;
				width: calc(100% + 2px);
				height: 50%;
				background-color: inherit;
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
				border: $border-style;
				border-top: none;
			}
		}
	}

	.result-stage {
		@include font-sans-serif-bold;
		display: inline-block;

		&:after {
			content: '|';
			margin: 0 1em;
		}
	}

	&.show-typeahead {
		.input-container {
			&:before,
			.typeahead-results {
				display: block;
			}
		}
	}
}
