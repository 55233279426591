.topic-details {

	.topic-title {
		@include font-sans-serif-semibold(24px)
	}

	.topic-description {
		margin-top: 1em;
	}

	.topic-link {
		display: inline-block;
		margin-top: 1em;

		&:after {
			position: relative;
			top: 2px;
			margin-left: .5em;
			@include font-icons($icon-external-link);
		}

		&:hover {
			color: $color-gray-trout;
		}
	}

	.resource-title {
		@include font-sans-serif-semibold(20px);
		margin-top: 2em;
	}
}
