$base-font-size: 16px;
$base-font-size-value: 16;

$font-size-h1: 48px;
$font-size-h2: 32px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;

.h1 {
	font-size: px-to-em(48px);
}

.h2 {
	font-size: px-to-em(32px);
}

.h3 {
	font-size: px-to-em(18px);
}

.h4 {
	font-size: px-to-em(16px);
}

.h5 {
	font-size: px-to-em(14px);
}


.section-header {
	font-size: px-to-em(36px);
	text-transform: uppercase;
	color: $color-black;
	text-align: center;
	letter-spacing: 2px;
	line-height: 1;
}

.section-intro {
	margin-top: 2em;
	text-align: left;
}

.section-subheader {
	font-size: px-to-em(28px);
	text-transform: uppercase;
	color: $color-black;
	display: inline-block;
	letter-spacing: 1px;
	margin-bottom: .5em;
}

.overview-text {
	color: $color-latham-red;
	@include font-sans-serif-light(24px);
}

.tagline-text {
	font-size: px-to-em(18px);
}
