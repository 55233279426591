#development-stages,
#development-stages-mobile {
	$duration: 1.5s;

	@include font-sans-serif-light;

	circle {
		transition: fill $transition-duration-primary * 2,
			stroke $duration,
			stroke-width $transition-duration-primary;
	}

	text {
		text-anchor: middle;
		transition: fill $duration, font-size .75s ease-in-out, font-weight $transition-duration-primary;
		fill: $color-white;
	}

	.stage-node {
		cursor: pointer;

		circle {
			fill: $color-latham-red;
		}

		text {
			font-size: px-to-em(26px);
		}

		line {
			stroke: $color-white;
			stroke-width: 2px;
		}

		&.active-node,
		&:hover {
			circle {
				fill: $color-gray-woodsmoke;
			}
		}
	}

	.topic-node {

		circle {
			fill: $color-white;
			stroke: $color-gray-gainsboro;
		}

		&.active-node {
			cursor: pointer;

			circle {
				stroke: $color-latham-red;
			}

			text {
				fill: $color-latham-red;
			}

			&:hover {
				circle {
					stroke-width: 2px;
				}

				text {
					font-weight: 500;
				}
			}
		}
	}

	svg {
		max-width: 100%;
	}
}

#development-stages {
	max-width: px-to-em(1000px);
	padding: 0 $page-edge-size + 1em;
	margin-left: auto;
	margin-right: auto;
	transition: max-width .3s;

	//Ripple effect stylesgit 
	position: relative;

	@include bp-min(1000px){
		@include bp-min-height($bp-between) {
			max-width: px-to-em(1200px);
		}
	}

	@include bp-max($bp-between){
		padding: 0 $page-edge-size-between;
	}

	@include bp-max($bp-mobile) {
		position: absolute;
		z-index: -100;
	}

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		max-width: none;
		margin-top: 4em;
		svg {
			height: 70vh
		}

		@include bp-max-height($bp-between) {
			margin-top: 0;
		}
	}

	.ripple-effect{
	  position: absolute;
	  border-radius: 50%;
	  width: 50px;
	  height: 50px;
	  background: white;

	  animation: ripple-animation .5s;
	}


	@keyframes ripple-animation {
	    from {
	      transform: scale(1);
	      opacity: 0.4;
	    }
	    to {
	      transform: scale(10);
	      opacity: 0;
	    }
	}
}

#development-stages-mobile {
	@include font-sans-serif;
	width: 70%;
	margin: 0 auto;
	font-size: 22px;
}
