$font-family-icons: 'icomoon';
$icomoon-version: 'xamppq';

@font-face {
	font-family: $font-family-icons;
	src:  url($file-path-fonts + 'icomoon.eot?' + $icomoon-version);
	src:  url($file-path-fonts + 'icomoon.eot?' + $icomoon-version + '#iefix') format('embedded-opentype'),
		url($file-path-fonts + 'icomoon.otf?' + $icomoon-version) format('opentype'),
		url($file-path-fonts + 'icomoon.ttf?' + $icomoon-version) format('truetype'),
		url($file-path-fonts + 'icomoon.woff?' + $icomoon-version) format('woff'),
		url($file-path-fonts + 'icomoon.svg?' + $icomoon-version + '#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin font-icons ($icon-var, $font-size: null, $base-size: null){
	content: $icon-var;
	font-family: $font-family-icons;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@if ($font-size != null){
		@include font-size($font-size, $base-size);
	}
}

$icon-logo-footer-new: "\e91a";
$icon-layout: "\e918";
$icon-cog: "\e919";
$icon-arrow-left: "\e916";
$icon-arrow-up: "\e917";
$icon-arrow-right: "\e900";
$icon-arrow-down: "\e901";
$icon-menu: "\e902";
$icon-cross: "\e903";
$icon-plus: "\e904";
$icon-envelope: "\e90b";
$icon-paper-plane: "\e912";
$icon-info-circle: "\e905";
$icon-angle-up: "\e906";
$icon-info: "\e907";
$icon-search: "\e908";
$icon-play: "\e909";
$icon-music: "\e90a";
$icon-linkedin: "\e90c";
$icon-twitter: "\e90d";
$icon-angle-down: "\e90e";
$icon-angle-left: "\e90f";
$icon-download: "\e910";
$icon-file: "\e911";
$icon-users: "\e913";
$icon-external-link: "\e914";
$icon-angle-right: "\e915";
$icon-twitter-x: "\e91b"
