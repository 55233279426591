.page-general-content {

	.main {
		background-color: $color-white-white-smoke;
		padding: 4em 0;
	}

	.general-section {
		&:not(:first-child){
			margin-top: 1em;
		}
	}
}
