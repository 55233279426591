.next-section {
	background-color: $color-white-white-smoke;
	text-align: center;
	padding: px-to-em(100px) 0;	
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:before,
	&:after {
		content:'';
		font-size: 0;
	}


	.btn-prev-item,
	.btn-next-item {
		position: relative;
		display: inline-block;
		letter-spacing: .5px;
		color: $color-latham-red;
		transform: translate3d(0, 50%, 0);
		
		&:before {
			position: absolute;
			display: inline-block;
			bottom: 0;
			font-size: 1.5em;
			line-height: 1;
			color: $color-gray-aluminum;
		}

		&:after {
			position: absolute;
			display: inline-block;
			bottom: 100%;
			color: $color-gray-aluminum;
			letter-spacing: 1px;
		}

		&:hover {
			color: $color-gray-trout;
		}
	}

	.btn-prev-item {
		&:before {
			@include font-icons($icon-angle-left);
			left: -1em;
		}

		&:after {
			content: 'Previous';
			left: 0;
		}
	}

	.btn-next-item {
		&:before {
			@include font-icons($icon-angle-right);
			right: -1em;
		}

		&:after {
			content: 'Next';
			right: 0;
		}
	}

	@include bp-max($bp-between){
		padding: px-to-em(48px) 0;
		flex-wrap: wrap;
		justify-content: space-around;

		&:before,
		&:after {
			order: 1;
			width: 100%;
		}

		.btn-prev-item,
		.btn-next-item {
			order: 2;
			margin-top: 2em;
		}
	}
}
