.stages-topics-tabs {
	padding: 0 2em;
	margin-top: .5em;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	transition: opacity .6s .3s;

	&.initial-animate {
		opacity: 0;
	}

	@for $i from 0 through 4 {
		&.show-stage-#{$i} {
			$nthChild: $i + 1;
			.btn-stage-select {
				&:nth-child(#{$nthChild}) {
					background-color: $color-latham-red;
					color: $color-white;
				}
			}

			.stage-topics-group {
				&:nth-child(#{$nthChild}) {
					$duration: .3s;
					$delay: .01s;
					transition: opacity $duration $delay,
						visibility $delay,
						transform $duration $delay;
					visibility: visible;
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}


	.stages-buttons {
		display: flex;
		justify-content: center;
	}

	.btn-stage-select {
		padding: .25em .75em;
		margin: .25em;
		line-height: 1;
		border: 1px solid $color-gray-catskill-white;
		color: $color-latham-red;
		background-color: $color-white;
		text-transform: uppercase;
		letter-spacing: .5px;
		transition: color .3s, background-color .3s;
		cursor: pointer;
	}

	.stage-topics {
		position: relative;
	}

	.stage-topics-group {
		$duration: .3s;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: absolute;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		transform: translate3d(0, 25px, 0);
		transition: opacity $duration,
			visibility 0s $duration,
			transform 0s $duration;
	}

	.stage-topic-link-container {
		width: 25%;
		text-align: center;
	}

	.stage-topic-link {
		font-size: px-to-em(14px);
		line-height: 1.1;
		display: inline-block;
		padding: .5em;
	}

	@include bp-max-height($bp-mobile-small) {
		.btn-stage-select {
			letter-spacing: 0;
			padding: .2em .25em;
			margin: 0 .1em;
			font-size: px-to-em(14px);
		}

		.stage-topics-group {
			margin-top: .5em;
		}

		.stage-topic-link {
			font-size: px-to-em(13px);
			padding: .25em;
		}
	}

	// iPhone 6 landscape chrome
	@include bp-max-height(310px) {
		.btn-stage-select {
			letter-spacing: 0;
			padding: .2em .25em;
			margin: 0 .1em;
		}

		.stage-topic-link {
			font-size: px-to-em(13px);
			padding: .25em;
		}

		.stage-topic-link-container {
			width: calc(100% / 5);
		}
	}

	// iPhone5 landscape adjustments
	@include bp-max-height(290px) {
		margin-top: 0;

		.btn-stage-select {
			font-size: px-to-em(14px);
			letter-spacing: 0;
			padding: .2em .25em;
			margin: 0 .1em;
		}

		.stage-topic-link {
			font-size: px-to-em(12px);
			padding: .25em;
		}

		.stage-topic-link-container {
			width: calc(100% / 6);
		}
	}

	// iPhone5 Portait adjustments
	@include bp-max($bp-mobile-small) {
		padding: 0 1.5em;

		.stages-buttons {
			flex-wrap: wrap;
		}


		.btn-stage-select {
			font-size: 1em;
			letter-spacing: 0;
			padding: .25em .25em;
			margin: .1em;
		}

		.stage-topics-group {
			margin-top: 0;
		}

		.stage-topic-link-container {
			width: calc(100% / 3);
		}

		.stage-topic-link {
			font-size: px-to-em(12px);
			padding: .5em;
		}
	}
}
