.btn-accordion-label {
	$padding: 3.5em;

	padding: $padding;
	padding-bottom: $padding + $accordion-label-triangle-size;
	margin-bottom: $accordion-label-triangle-size * -1;
	background-color: $color-black;
	background-size: cover;
	position: relative;
	text-align: center;
	display: block;
	width: 100%;
	transition: filter $transition-duration-primary;


	.cssfilters & {

		filter: saturate(100%);
	
		&:hover {
			filter: saturate(0);		
		}
	}

	// IE
	.no-cssfilters & {
		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color-black, .5);
			transition: background-color $transition-duration-primary;
		}

		&:hover {
			&:before {
				background-color: rgba($color-black, 0);
			}
		}
	}

	.label-text {
		font-size: px-to-em(24px);
		color: $color-white;
		letter-spacing: 1px;
		position: relative;
		line-height: 1.25;

		&:after {
			margin-left: .5em;
			@include font-icons($icon-plus);
			line-height: 1;
			transition: transform $transition-duration-primary;
			display: inline-block;
		}
	}

	.accordion-module.is-expanded & {

		.cssfilters & {
			filter: saturate(100%);
		}

		// IE
		.no-cssfilters & {
			&:before {
				background-color: rgba($color-black, 0);
			}
		}

		.label-text {
			&:after {
				transform: rotate(45deg);
			}
		}
	}

	.triangle {

		&:before,
		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			bottom: 0;
			height: 0;
			width: 50%;
			border-bottom: $accordion-label-triangle-size solid white;
		}

		&:before {
			left: 0;
			border-right: $accordion-label-triangle-size solid transparent;
		}

		&:after {
			right: 0;
			border-left: $accordion-label-triangle-size solid transparent;
		}
	}

	@include bp-max($bp-between) {
		$padding: 2.5em;

		padding: $padding;
		padding-bottom: $padding + $accordion-label-triangle-size;
	}

	@include bp-max(525px) {
		.label-text {
			font-size: px-to-em(20px);
		}
	}

	@include bp-max($bp-mobile) {
		$padding: 1.5em;

		padding: $padding;
		padding-bottom: $padding + $accordion-label-triangle-size;
	}

	@include bp-max($bp-mobile - 1px){
		.label-text {
			font-size: px-to-em(18px);
		}
	}
}
