%letter-nav-button-circle-style {
	$button-size: 3em;

	content: '';
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: $button-size;
	height: $button-size;
	border-radius: $button-size;
	border: 1px solid $color-latham-red;
	transition: border-color $transition-duration-primary;
}

.letter-navigation {
	text-align: center;
	$spacing: 2px;

	.letter-nav-button {
		position: relative;
		color: $color-latham-red;
		margin: 0 2em;
		transition: color $transition-duration-primary;

		&:hover {
			color: $color-gray-trout;

			&:after,
			&:before {
				border-color: $color-gray-trout;
			}
		}
	}

	.letter-prev {
		&:before {
			@include font-icons($icon-arrow-left);
			position: relative;
			top: 1px;
			margin-right: $spacing;
		}

		&:after {
			@extend %letter-nav-button-circle-style;
		}
	}

	.letter-next {
		&:before {
			@extend %letter-nav-button-circle-style;
		}

		&:after {
			@include font-icons($icon-arrow-right);
			margin-left: $spacing;
			position: relative;
			top: 1px;
		}
	}
}
