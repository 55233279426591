.form-input-field {
	$side-padding: 16px;

	border: 1px solid $color-latham-red;
	border-radius: $form-input-border-radius;
	background-color: $color-white;

	.field-input {
		width: 100%;
		padding: .75em px-to-em($side-padding);
		color: $color-black;
		letter-spacing: 1px;
		border-radius: inherit;

		@include placeholder {
			color: $color-gray-aluminum;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}

	&.field-email {
		position: relative;
		padding-left: px-to-em($side-padding) * 2.5;

		&:before {
			$font-size: 20px;

			@include font-icons($icon-envelope);

			font-size: px-to-em($font-size);
			position: absolute;
			top: 50%;
			left: px-to-em($side-padding, $font-size);
			transform: translate3d(0, -55%, 0);
			color: $color-gray-silver;
		}

		.field-input {
			padding-left: .5em;
		}
	}
}
