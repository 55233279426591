.page-resources-book-of-jargon {
	$spacing: px-to-em(96px);

	.resources-content {
		background-color: $color-white-white-smoke;
		padding: $spacing 0;
	}

	@include bp-max($bp-between){
		$spacing: px-to-em(48px);

		.resources-content {
			padding: $spacing 0;
		}
	}
}
