@use "sass:math";

.site-search {
	$edge-duration: .25s;
	$input-line-duration: .25s;
	$opacity-duration: .25s;

	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	visibility: hidden;
	transition: visibility $edge-duration + $input-line-duration;

	&:before,
	&:after {
		content: '';
		display: inline-block;		
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $color-white;
		transition: transform $edge-duration $input-line-duration;
	}

	&:before {
		transform: translate3d(0, -100%, 0);
	}

	&:after {
		transform: translate3d(0, 100%, 0);
	}

	.ss-input-container {
		position: absolute;
		width: calc(100% - 4em);
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 1;
		display: flex;
		max-width: px-to-em(600px);

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 110%;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			background-color: $color-latham-red;
			width: 0;
			height: 2px;
			transition: width $input-line-duration;
		}

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			&:after {
				content: 'Enter Search Term';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 0;
				font-size: 3em;

				@include bp-max($bp-between) {
					font-size: math.div(2, 3) * 1em;
				}
			}

			&.hide-ie-placeholder {
				&:after {
					opacity: 0;
				}
			}

			@include placeholder {
				color: $color-white;
			}
		}
	}

	.ss-input,
	.btn-ss-submit {
		opacity: 0;
		transition: opacity $opacity-duration;
		font-size: 3em;
	}

	.ss-input {
		flex-grow: 1;
		position: relative;
		z-index: 1;
		background-color: rgba(0,0,0,0);
	}

	.btn-ss-submit {
		&:before {
			color: $color-latham-red;
			@include font-icons($icon-search);
			position: relative;
			top: 2px;
		}
	}

	.show-site-search & {
		visibility: visible;
		transition: visibility 0s;

		&:before {
			transform: translate3d(0, -49%, 0);
			transition: transform $edge-duration;
		}

		&:after {
			transform: translate3d(0, 49%, 0);
			transition: transform $edge-duration;
		}

		.ss-input-container {
			&:before {
				transition: width $input-line-duration $edge-duration + .1s;
				width: 100%;
			}
		}

		.ss-input,
		.btn-ss-submit {
			opacity: 1;
			transition: opacity $opacity-duration $input-line-duration + $edge-duration;
		}
	}

	@include bp-max($bp-between) {
		.ss-input,
		.btn-ss-submit {
			font-size: 2em;
		}
	}

	@include bp-max($bp-mobile) {
		.ss-input,
		.btn-ss-submit {
			font-size: 1.5em;
		}
	}
}
