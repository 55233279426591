.practice-leaders {

	background-color: $color-white;

	.section-intro {
		text-align: center;
		color: $color-black;
		margin-top: 1em;
	}

	.filter-tabs {
		margin-top: 2.5em;
	}

	.professional-tile-grid {
		max-width: px-to-em(1000px) + $professional-tile-grid-padding * 2;
		margin: 3.5em auto 0;
	}

}
