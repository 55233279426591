.content-card {
	$transition: .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

	position: relative;
	width: px-to-em(300px);
	display: inline-block;
	cursor: pointer;
	background-color: $color-latham-red;

	.card-link {
		color: $color-white;

		&:hover {
			color: $color-white;
		}
	}

	.card-industry {
		$font-size: 12px;

		text-transform: uppercase;
		background-color: $color-latham-red;
		line-height: 1;
		padding: px-to-em(18px, $font-size) px-to-em(24px, $font-size);

		@include font-sans-serif-bold($font-size);
	}

	.card-background {
		height: px-to-em(310px);
		background-size: cover;
		transition: filter $transition-duration-primary,
			transform $transition;
	}

	&:hover {
		.card-background {
			transform: scale(.95);
		}

		.card-details {
			transform: translate3d(0, -0.5em, 0);
		}
	}

	.cssfilters & {
		.card-background {
			filter: saturate(100%) brightness(.75);
		}

		&:hover {
			.card-background {
				filter: saturate(0) brightness(.75);
			}
		}

	}

	// IE
	.no-cssfilters & {
		.card-background {		
			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color-black, .5);
				transition: background-color $transition-duration-primary;
			}
		}

		&:hover {
			.card-background {				
				&:before {
					background-color: rgba($color-black, 0);
				}
			}
		}
	}

	.card-details {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 1.5em;
		transition: transform $transition;

	}

	.card-content-type {
		$font-size: 12px;

		font-size: px-to-em($font-size);
		line-height: px-to-em(28px, $font-size);
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.card-title {
		$font-size: 24px;
		$line-height: px-to-em(30px, $font-size);

		font-size: px-to-em($font-size);
		line-height: $line-height;
		letter-spacing: 1px;
		
		max-height: 5 * $line-height; // max-height 5 lines
		display: -webkit-box;
		line-clamp: 5;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;  
		overflow: hidden;
	}

	.card-publication-date,
	.content-author {
		$font-size: 12px;

		font-size: px-to-em($font-size);
		line-height: px-to-em(20px, $font-size);
		letter-spacing: .5px;
	}

	.card-publication-date {
		&:after {
			content: '\00a0'
		}
	}

	.stage-visual {
		margin-top: 1em;

		&.is-hidden {
			visibility: hidden;
		}
	}
}
