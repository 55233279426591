.accordion-module {
	background-color: $color-white;
	overflow-y: hidden;
	overflow-x: auto;
	border-bottom: 1px solid $color-gray-gainsboro;

	.accordion-content {

		padding: px-to-em(90px) 0;
		position: relative;
		display: none;
		text-align: left;
		transform: translate3d(0,0,0);
	}
	.content-section {
		margin-top: 3em;

		&:first-of-type {
			margin-top: 0;
		}
	}

	.content-brief {
		margin-top: 1em;
		color: $color-gray-trout;

		@include font-sans-serif;
	}

	.learn-more-link {
		display: inline-block;
		margin-top: 1em;

		&:after {
			@include font-icons($icon-external-link);
			position: relative;
			top: 2px;
			margin-left: .5em;
		}

		&:hover {
			color: $color-gray-trout;
		}
	}

	.content-card-list {
		margin-top: 2em;
		justify-content: space-between;
	}

	.btn-close-accordion {
		$position: 1.5em;

		position: absolute;
		bottom: $position;
		right: $position;
		text-transform: uppercase;
		color: $color-latham-red;
		text-decoration: underline;

		@include font-sans-serif-bold(14px);
	}

	@include bp-max($bp-between) {
		.accordion-content {
			padding: px-to-em(60px) 0;
		}
	}
}
