.faq-search {
	$stage-select-width: px-to-em(170px);
	$gutter: 3em;

	margin-top: 4em;

	.search-input-typeahead {
		z-index: 1;
	}

	.faq-results-grid {
		$spacing: 2em;

		margin-top: $spacing;
		padding-top: $spacing;
		border-top: 1px solid $color-black;
		display: flex;
	}

	.left-side {
		width: $stage-select-width;
		margin-right: $gutter;
	}

	.right-side {
		width: calc(100% - #{$stage-select-width + $gutter});
	}

	@include bp-max($bp-between){
		margin-top: 4em;
	}

	@include bp-max(600px){
		.faq-results-grid {
			display: block;
		}

		.left-side {
			width: auto;
			margin: 0;
		}

		.right-side {
			width: auto;
			margin-top: 2em;
		}
	}
}
