.page-experience-landing {
	.clients {
		background-color: $color-white;
		padding: px-to-em(96px) 0;
		text-align: center;

		.section-header {
			margin-top: 1em;
		}

		.expanding-grid {
			margin-top: 5em;
		}
	}


	.industries {
		text-align: center;
		background-color: $color-white;
		padding: px-to-em(96px) 0;

		.section-intro {
			text-align: left;
		}

		.capability-accordions {
			margin-top: 5em;
		}

		.cta-see-all-capabilities {
			display: inline-block;
			margin-top: 4em;
			font-size: px-to-em(18px);
			letter-spacing: 1.25px;
			color: $color-latham-red;

			&:after {
				margin-left: 1em;
				@include font-icons($icon-external-link);
				position: relative;
				top: 2px;
			}

			&:hover {
				color: $color-gray-trout;
			}
		}
	}

	@include bp-max(880px){
		$spacing: px-to-em(48px);

		.clients {
			padding: $spacing 0;
		}

		.industries {
			padding: $spacing 0;

			.cta-see-all-capabilities {
				margin-top: 2em;
			}
		}
	}

	@include bp-max($bp-mobile){
		.clients {
			padding: 1em 0;
		}
	}
}
