.filter-tabs {
	$bp-drop-down: 700px;
	$border-style: 1px solid $color-gray-gainsboro;

	text-transform: uppercase;
	border-bottom: $border-style;

	.filter-prompt {
		color: $color-black;
	}

	.drop-down-option {
		letter-spacing: .75px;
		color: $color-latham-red;

	}

	@include bp-min($bp-drop-down){

		text-align: center;


		.current-option {
			display: none;
		}

		.drop-down-options {
			display: inline-flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-left: 1em;
		}

		.drop-down-option {
			padding: .75em;
			margin: 0 .5em;
			cursor: pointer;
			border: 1px solid transparent;
			border-bottom: none;
			transition: border-color $transition-duration-primary,
				color $transition-duration-primary;

			&.selected-option {
				color: $color-black;
				border-color: $color-gray-gainsboro;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: calc(100% - 1px);
					left: 0;
					height: 3px;
					width: 100%;
					background-color: $color-white;
				}
			}
		}
	}

	@include bp-max($bp-drop-down){
		position: relative;
		z-index: 1;
		$padding: .5em;

		.current-option {
			display: block;
			border: $border-style;
			padding: $padding;
			color: $color-black;
			cursor: pointer;
			margin-top: 1em;

			&:after {
				@include font-icons($icon-angle-down);
				position: relative;
				top: 3px;
				float: right;
				display: inline-block;
				transition: transform $transition-duration-primary;
			}
		}

		&.show-drop-down {
			.current-option {
				&:after {
					transform: rotateX(180deg);
				}
			}

			.drop-down-options {
				display: block;
			}
		}

		.drop-down-options {
			background-color: $color-white;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			border: $border-style;
			border-top: none;
			cursor: pointer;
			display: none;
		}

		.drop-down-option {
			padding: $padding;
			transition: background-color $transition-duration-primary;

			&.selected-option {
				display: none;
			}

			&:hover {
				background-color: $color-white-white-smoke;
			}
		}
	}
}
