@use "sass:math";

.mobile-menu {
	$vertical-spacing: px-to-em(20px);
	$edge-duration: .25s;
	$fade-duration: .25s;
	$link-size: px-to-em(45px);

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	text-transform: uppercase;
	visibility: hidden;
	transition: visibility 0s $fade-duration + $edge-duration;
	display: none;

	.mm-edge {
		width: 100%;
		height: 100%;
		background-color: $color-white;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: transform $edge-duration $fade-duration;
	}

	.mm-left{
		transform: translate3d(-100%, 0, 0);
	}

	.mm-right{
		transform: translate3d(100%, 0, 0);
	}

	.mm-top{
		transform: translate3d(0, -100%, 0);
	}

	.mm-bottom{
		transform: translate3d(0, 100%, 0);
	}

	.show-mobile-menu &{
		visibility: visible;
		transition: visibility 0s;

		.mm-edge {
			transition: transform $edge-duration; 
		}

		.mm-left{
			transform: translate3d(-50%, 0, 0);
		}

		.mm-right{
			transform: translate3d(50%, 0, 0);
		}

		.mm-top{
			transform: translate3d(0, -50%, 0);
		}

		.mm-bottom{
			transform: translate3d(0, 50%, 0);
		}

		.mm-content {
			visibility: visible;
			opacity: 1;
			transition: opacity $fade-duration $edge-duration + .01s, visibility 0.01s; 
		}
	}

	.mm-content {
		margin-top: 10em;
		visibility: hidden;
		opacity: 0;
		transition: opacity $fade-duration, visibility 0s $fade-duration; 
	}

	.mm-nav-link-container {
		padding: $vertical-spacing 0;
	}

	.mm-nav-link {
		color: $color-latham-red;
		font-size: $link-size;
		letter-spacing: 1px;
	}

	.mm-social-links {
		padding: $vertical-spacing;
	}

	.mm-social-link {
		color: $color-latham-red;
		margin-right: .5em;
		font-size: $link-size;

		&:last-of-type {
			margin-right: 0;
		}
	}

	.mm-social-twitter {
		&:after {
			@include font-icons($icon-twitter-x);
		}
	}

	.mm-social-linkedin {
		&:after {
			position: relative;
			bottom: 1px;
			@include font-icons($icon-linkedin);
		}
	}

	.mm-footer-content {
		position: absolute;
		left: 50%;
		bottom: 3em;
		transform: translate3d(-50%, 0, 0);
		width: 100%;
	}

	.mm-logo-link {
		&:before {
			@include font-icons($icon-logo-footer-new);
			font-size: 2em;
			line-height: 0;
			position: relative;
			top: 5px;
			color: $color-gray-trout;
		}
	}

	.mm-footer-links {
		margin-top: 1em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1em;
	}

	.mm-footer-link-container {
		$spacing: 2em;

		margin-right: $spacing;
		position: relative;

		&:after {
			content: '|';
			font-size: 1.15em;
			line-height: 1;
			position: absolute;
			right: math.div($spacing, -2);
			top: 50%;
			transform: translate3d(50%, -60%, 0);
		}

		&:last-of-type {
			margin-right: 0;

			&:after {
				content: none;
			}
		}
	}

	.mm-footer-link {
		color: $color-gray-shuttle-gray;
	}

	@include bp-max($bp-mobile-menu){
		display: block;
	}

	@include bp-max($bp-between) {
		.mm-content {
			margin-top: 5em;
		}
		.mm-nav-link,
		.mm-social-link {
			font-size: px-to-em(30px);
		}
	}

	@include bp-max($bp-mobile){
		.mm-footer-links {
			font-size: px-to-em(12px);
		}
	}

	@include bp-max($bp-mobile-small){
		.mm-nav-link-container {
			padding: .75em 0;
		}
	}

	@include bp-max-height($bp-mobile){
		.mm-nav-links {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}

		.mm-nav-link-container {
			padding: 0;
		}

		.mm-social-links {
			padding: 0;
		}

		.mm-footer-content {
			bottom: $page-edge-size-mobile;
		}

		.mm-footer-links {
			font-size: px-to-em(12px);
			margin-top: 0;
		}
	}

	@include bp-max-height($bp-mobile-small){
		.mm-nav-links {
			margin-top: -3em;
		}

		.mm-nav-link,
		.mm-social-link {
			font-size: 1.5em;
		}

		.mm-nav-link-container {
			padding: .75em 0;
		}
	}
}
