.profile-header {
	$spacing: 24px;
	$animation-duration: .5s;

	position: relative;

	&.init-animation {
		.background-image-container {
			&:after {
				opacity: 1;
			}
		}
	}

	.background-image-container {
		background-size: cover;
		display: block;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
		min-height: 50vw;
		background-color: $color-gray-trout;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $color-gray-nero;
			opacity: 0;
			transition: opacity $animation-duration * 2;
		}
	}

	.background-image {
		display: block;
		width: 100%;
	}

	.profile-content {
		margin-left: auto;
		position: absolute;
		top: 50%;
		left: 55%;
		right: 5%;
		transform: translate3d(0, -50%, 0);
		font-size: 1.25em;
		transition: transform $animation-duration,
			opacity $animation-duration * 2;
	}

	.global-nav-trigger {
		position: absolute;
		top: -1em;
	}

	.profile-name {
		color: $color-white;
		text-transform: uppercase;
	}

	.full-name {
		$font-size: 40px;

		font-size: px-to-em($font-size);
		line-height: px-to-em(43px, 40px);
		letter-spacing: 2px;
	}

	.professional-role {
		$font-size: 24px;

		font-size: px-to-em($font-size);
		line-height: px-to-em(32px, $font-size);
		letter-spacing: 1px;
		color: $color-white-white-smoke;
		margin-top: px-to-em($spacing, $font-size)*0.5;
	}

	.contact-information {
		$border-style: 1px solid $color-gray-shady-lady;

		border-top: $border-style;
		border-bottom: $border-style;
		display: inline-table;
		padding: px-to-em($spacing) 0;
		margin-top: px-to-em($spacing);
	}

	.contact-row {
		display: table-row;
	}

	.contact-type {
		display: table-cell;
		width: 35%;
		color: $color-white;
	}

	.contact-detail {
		display: table-cell;
		width: 70%;
		color: $color-white;
		padding-left: 1em;

		a {
			color: $color-white;
			text-decoration: underline;

			.no-touchevents &:hover {
				color: $color-gray-gainsboro;
			}
		}
	}

	.social-links {
		margin-top: px-to-em($spacing);
	}

	.social-link-container {
		display: inline-block;
		margin-right: 1em;

		&:last-of-type {
			margin-right: 0;
		}
	}

	.social-link {
		$size: px-to-em(28px);

		display: inline-block;
		width: $size;
		height: $size;
		border-radius: $size;
		background-color: $color-white;
		border: 1px solid $color-latham-red;
		position: relative;
		transition: background-color $transition-duration-primary;

		&:after {
			transition: color $transition-duration-primary;
			color: $color-latham-red;
			position: absolute;
			top: 50%;
			left: 50%;
		}

		&:hover {
			background-color: $color-latham-red;

			&:after {
				color: $color-white;
			}
		}
	}

	.social-linkedin {
		&:after {
			@include font-icons($icon-linkedin);
			transform: translate3d(-45%, -55%, 0);
		}
	}

	.social-twitter {
		&:after {
			@include font-icons($icon-twitter-x);
			transform: translate3d(-50%, -50%, 0);
		}		
	}

	@include bp-max(1300px) {
		.profile-content {
			font-size: 1em;
		}
	}

	@include bp-max(1100px) {

		.background-image-container {
			min-height: px-to-em(530px);
			background-position: 60% 0%;
		}

		.background-image {
			display: none;
		}

		.profile-content {
			top: 55%;
		}
	}

	@include bp-max(950px){
		.first-name,
		.last-name {
			font-size: px-to-em(32px);
		}

		.profile-content {
			left: 50%;
		}
	}

	@include bp-min($bp-between){
		&.init-animation {
			.profile-content {
				transform: translate3d(0, 3em, 0);
				opacity: 0;
			}
		}
	}

	@include bp-max($bp-between){
		&:after {
			content: none;
		}

		.background-image-container {
			min-height: px-to-em($global-nav-height);
			background-position: 0% 0;
		}

		.background-image{
			display: block;
			opacity: 0;
			width: 150%;
		}

		.profile-content {
			position: static;
			transform: none;
			background-color: $color-latham-red;
			padding: 2em;
		}

		.global-nav-trigger {
			position: relative;
			top: -6em;
		}
	}

	@include bp-max(500px) {
		$mobile-spacing: $spacing * .75;

		.first-name,
		.last-name {
			font-size: px-to-em(24px);
		}

		.professional-role {
			font-size: px-to-em(18px);
		}

		.contact-information {
			margin-top: px-to-em($mobile-spacing);
			padding: px-to-em($mobile-spacing) 0;
		}

		.social-links {
			margin-top: px-to-em($mobile-spacing);
		}
	}

	@include bp-max($bp-mobile - 1px){
		.profile-content {
			padding: 1em;
		}
	}

	@include bp-max(374px){

		.contact-information {
			display: block;
		}

		.contact-row {
			display: block;
			margin-top: .5em;

			&:first-of-type {
				margin-top: 0;
			}
		}

		.contact-type,
		.contact-detail {
			display: block;
			width: auto;
			padding-left: 0;
		}

		.contact-type {
			@include font-sans-serif-semibold;
		}
	}
}
