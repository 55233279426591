@use "sass:math";

.advice-accordion {
	$spacing: 32px;
	$box-shadow-style: 0 5px 25px 0 rgba($color-black, .1);

	padding-left: px-to-em($spacing);
	background-color: $color-white;
	transition: box-shadow $transition-primary;

	&:hover {
		box-shadow: $box-shadow-style;
	}

	.accordion-button {
		display: block;
		width: 100%;
		text-align: left;
		padding: .5em .75em .5em 0;
		font-size: px-to-em(18px);
		color: $color-latham-red;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:before {
			@include font-icons($icon-plus);
			$font-size: 12px;

			font-size: $font-size;
			margin-right: 1em;
			display: inline-block;
			transition: transform $transition-duration-primary;
			color: $color-black;
			position: absolute;
			left: math.div(px-to-em($spacing, $font-size), -2);
			top: 50%;
			transform: translate3d(-25%, -55%, 0);
			font-size: .7em;
		}
	}

	.accordion-content {
		padding: 1.5em 1em 1.5em 0;
		display: none;
	}

	&.is-expanded {
		box-shadow: $box-shadow-style;

		.accordion-button {
			&:before {
				transform: translate3d(-25%, -55%, 0) rotate(45deg);
			}
		}
	}
}
