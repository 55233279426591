.homepage-edge {
	position: fixed;
	background-color: $color-white;
	transition: transform $homepage-edge-transition-duration;
	z-index: $z-index-page-edges;

	&.edge-left {
		top: 0;
		left: 0;
		width: $page-edge-size;
		height: 100vh;

		&.edge-init-position {
			transform: translate3d(-100%, 0, 0);
		}
	}

	&.edge-right {
		top: 0;
		right: 0;
		width: $page-edge-size;
		height: 100vh;

		&.edge-init-position {
			transform: translate3d(100%, 0, 0);
		}
	}

	&.edge-top {
		top: 0;
		left: 0;
		height: $page-edge-size;
		width: 100vw;

		&.edge-init-position {
			transform: translate3d(0, -100%, 0);
		}
	}

	&.edge-bottom {
		bottom: 0;
		left: 0;
		height: $page-edge-size;
		width: 100vw;

		&.edge-init-position {
			transform: translate3d(0, 100%, 0);
		}
	}

	@include bp-max($bp-between){
		&.edge-left,
		&.edge-right {
			width: $page-edge-size-between;
		}

		&.edge-top,
		&.edge-bottom {
			height: $page-edge-size-between;
		}
	}

	@include bp-max($bp-mobile){
		&.edge-left,
		&.edge-right {
			width: $page-edge-size-mobile;
		}

		&.edge-top,
		&.edge-bottom {
			height: $page-edge-size-mobile;
		}
	}

	@include bp-max-height($bp-mobile){
		&.edge-left,
		&.edge-right {
			width: $page-edge-size-mobile;
		}

		&.edge-top,
		&.edge-bottom {
			height: $page-edge-size-mobile;
		}
	}
}
