.form-drop-down {
	position: relative;
	z-index: 1;
	border: 1px solid $color-latham-red;
	border-radius: $form-input-border-radius;

	&.show-dropdown {
		.drop-down-options {
			display: block;
		}

		.current-value {
			&:after {
				transform: rotateX(180deg);
			}
		}
	}

	.drop-down-options {
		position: absolute;
		top: calc(100% - 1px);
		left: -1px;
		width: calc(100% + 2px);
		background-color: $color-white;
		border: 1px solid $color-latham-red;
		display: none;
		max-height: 25vh;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.current-value,
	.drop-down-option {
		padding: .5em 1em;
		cursor: pointer;
	}

	.current-value {
		$side-padding: 16px;
		
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: .75em px-to-em($side-padding);
		background-color: $color-white;

		&:after {
			@include font-icons($icon-angle-down);
			color: $color-latham-red;
			position: relative;
			font-size: 1.25em;
			transition: transform $transition-duration-primary;
		}
	}

	.drop-down-option {
		width: 100%;
		text-align: left;
		transition: background-color $transition-duration-primary;

		&.is-selected {
			display: none;
		}

		&:hover {
			background-color: $color-gray-gainsboro;
		}
	}
}
