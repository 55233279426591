.rich-text,
.mce-content-body  {
	$spacing: 1em;
	p {
		margin-bottom: 0;

		&:not(:first-child) {
			margin-top: $spacing;
		}
	}

	li {

		&:not(:first-child) {
			margin-top: .5em;
		}
	}

	ul {
		list-style-type: none;
		margin-left: $spacing;

		&:not(:first-child) {
			margin-top: $spacing;
		}

		li {
			&:before {
				margin-right: -.4em;
				content: '•';
				position: relative;
				right: 1em;
				color: $color-latham-red;
			}
		}
	}

	ol {
		margin-left: $spacing;

		&:not(:first-child) {
			margin-top: $spacing;
		}
	}

	table {
		&:not(:first-child) {
			margin-top: $spacing;
		}

		&.table-border-red {
			$border-style: 1px solid $color-latham-red;

			border: $border-style;

			tr:not(:last-child) {
				border-bottom: $border-style;
			}
			td:not(:last-child) {
				border-right: $border-style;
			}
		}

		&.table-border-black {
			$border-style: 1px solid $color-black;

			border: $border-style;

			tr:not(:last-child) {
				border-bottom: $border-style;
			}
			td:not(:last-child) {
				border-right: $border-style;
			}
		}


		td {
			padding: px-to-em(5px) px-to-em(10px);
			vertical-align: top;
		}
	}

	> *:first-child {
		margin-top: 0;
	}
}

// mce-content-body is used in the keystone rich text editor
.mce-content-body {
	font-size: 16px;
}

.wp-video {
	width: 100% !important;
}

.wp-video-shortcode {
	max-width: 100%;
	height: auto;
}