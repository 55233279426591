.filter-checkbox {
	$spacing: 1.5em;

	color: $color-latham-red;
	line-height: 1.25;
	letter-spacing: .75px;
	padding-left: $spacing;
	position: relative;
	cursor: pointer;

	&:before {
		$size: px-to-em(15px);

		content: '';
		display: inline-block;
		position: absolute;
		top: .15em;
		left: 0;
		height: $size;
		width: $size;
		border-radius: .25em;
		border: 1px solid $color-gray-silver;
		transition: background-color $transition-duration-primary,
			border-color $transition-duration-primary;
	}

	.stage-diagram {
		display: inline-flex;
		justify-content: space-between;
		width: px-to-em(175px);
		position: relative;
		vertical-align: middle;
		top: -2px;
		margin: 0 2.5em;
		align-items: center;

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate3d(0, -50%, 0);
			width: 100%;
			height: 1px;
			background-color: $color-gray-gainsboro;
		}
	}

	.diagram-circle {
		$size: px-to-em(7px);

		height: $size;
		width: $size;
		border-radius: $size;
		border: 1px solid $color-gray-gainsboro;
		background-color: $color-gray-gainsboro;
		position: relative;

		&.current-stage {
			background-color: $color-latham-red;
			border-color: $color-latham-red;
		}
	}

	&.is-selected {
		&:before {
			background-color: $color-latham-red;
			border-color: $color-latham-red;
		}
	}
}
