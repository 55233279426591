.search-filter-type {
	.search-filter-slide-down {
		background-color: $color-white;
		border-bottom: 1px solid $color-gray-gainsboro;
		padding: 4em 0;
	}

	.filter-grid {
		display: flex;
		width: 100%;
	}

	.filter-stages-container {
		text-align: center;

	}

	.filter-stages {
		text-align: left;
		display: inline-block;

		.filter-checkbox {
			width: auto;
			display: flex;
		}
	}

	.filter-list {
		columns: 3;
	}

	.filter-checkbox {
		$spacing: .5em;

		padding-top: $spacing;
		padding-bottom: $spacing;
		display: inline-block;
		width: 100%;

		&:before {
			top: $spacing + .15em;
		}
	}

	.stage-filter-container {
		$side-padding: 2em;
		$icon-width: 1em;

		position: relative;

		.is-stage-label {
			padding-left: $side-padding + $icon-width;
		}

		.stage-info-icon {
			left: $side-padding;
		}
	}

	.stage-info-icon {
		position: absolute;
		color: $color-gray-gainsboro;
		padding: 0;
		line-height: 1;
		transition: color $transition-duration-primary;
		z-index: 5;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		cursor: pointer;

		&:before {
			@include font-icons($icon-info-circle);
		}

		&:hover {
			color: $color-gray-trout;
		}
	}

	// Breakpoint matches _btn-filter-label
	@include bp-max($bp-between){
		.stage-filter-container {
			$side-padding: 1em;
			$icon-width: 1em;

			.is-stage-label {
				padding-left: $side-padding + $icon-width;
			}

			.stage-info-icon {
				left: $side-padding;
			}
		}
	}

	@include bp-max(730px) {
		.filter-list {
			columns: 2;
		}
	}

	// Breakpoint matches _btn-filter-label
	@include bp-max(525px){
		.stage-filter-container {
			$side-padding: .5em;
			$icon-width: 1em;

			.is-stage-label {
				padding-left: $side-padding + $icon-width;
			}

			.stage-info-icon {
				left: $icon-width;
			}
		}
	}

	@include bp-max(500px) {
		.filter-list {
			columns: 1;
		}

		.filter-checkbox {
			.stage-diagram {
				margin: 0 1em;
				max-width: px-to-em(100px);
			}
		}
	}

	// Breakpoint matches _btn-filter-label
	@include bp-max(374px){
		.stage-filter-container {
			$side-padding: .25em;
			$icon-width: 1em;

			.is-stage-label {
				padding-left: $side-padding + $icon-width;
			}

			.stage-info-icon {
				left: $side-padding;
			}
		}
	}
}
