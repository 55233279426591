.global-nav {
	position: fixed;
	top: $page-edge-size;
	left: $page-edge-size;
	right: $page-edge-size;
	z-index: $z-index-global-nav;
	padding: $page-edge-size*0.5;
	padding-right: $page-edge-size;
	display: flex;
	transition: opacity $transition-duration-primary 0.01s,
		visibility 0.01s,
		background-color $transition-duration-primary ease-in-out;

	&.homepage-init {
		opacity: 0;
		visibility: hidden;
	}

	.company-logo {
		flex-grow: 1;
	}

	.logo-link {
		display: block;
		position: relative;
	}

	.logo-img {
		display: block;
		width: px-to-em(205px);
		transition: opacity $transition-duration-primary;
	}


	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	     /* IE10+ CSS styles go here */
		.logo-img {
			width: px-to-em(205px);
			height: px-to-em(47px);
		}
	}

	.logo-dark {
		.page-homepage.fp-viewing-0 & {
			opacity: 0;
		}
	}

	.logo-white {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity $transition-duration-primary;

		.page-homepage.fp-viewing-0 & {
			opacity: 1;
		}
	}

	.nav-links {
		display: flex;
		align-items: center;
	}

	.nav-link-container {
		$spacing: 2em;

		margin-right: $spacing;
		padding-right: $spacing;
		position: relative;

		&:after {
			content: '';
			display: inline-block;
			width: 1px;
			height: 80%;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate3d(0, -50%, 0);
			background-color: rgba($color-gray-dark-gray, 1);
		}

		&:last-of-type {
			margin-right: 0;

			&:after {
				content: none;
			}
		}
	}

	.nav-link {
		text-transform: uppercase;
		letter-spacing: 3px;
		color: $color-gray-catskill-white;
		display: inline-block;
		position: relative;

		@include font-sans-serif-semibold;


		&:after {
			content: '';
			display: inline-block;
			width: 0;
			height: 1px;
			position: absolute;
			bottom: -5px;
			left: 0;
			background-color: $color-gray-catskill-white;
			transition: width $transition-primary;
		}

		&.is-current-section {
			&:after {
				width: 100%;
			}
		}

		.no-touchevents &:hover {
			&:after {
				width: 100%;
			}
		}
	}

	.btn-mobile-menu {
		display: none;
		margin-right: 1em;
		position: relative;
		z-index: 1;

		&:before {
			@include font-icons($icon-menu);

			color: $color-gray-catskill-white;
			transition: color $transition-duration-primary, transform $transition-duration-primary;
			display: inline-block;
			font-size: 2em;
			position: relative;
			top: 7px;
			line-height: 0;

		}

		.no-touchevents &:hover {
			&:before {
				transform: scale(1.25);
			}
		}
	}

	.btn-site-search {
		position: relative;
		z-index: 1;

		&:before {
			color: $color-gray-catskill-white;
			transition: color $transition-duration-primary, transform $transition-duration-primary;
			font-size: 1.5em;
			line-height: 0;
			display: inline-block;

			@include font-icons($icon-search);
		}

		.no-touchevents &:hover {
			&:before {
				transform: scale(1.25);
			}
		}
	}

	&.show-mobile-menu,
	&.show-site-search {
		.btn-mobile-menu,
		.btn-site-search {
			&:before {
				color: $color-latham-red;
			}
		}
	}

	&.show-mobile-menu {
		.btn-mobile-menu {
			&:before {
				font-size: 1.75em;
				position: relative;
				top: 3px;

				@include font-icons($icon-cross);
			}
		}
	}

	&.show-site-search {
		.btn-site-search {
			&:before {
				font-size: 2em;
				width: 22.3px;
				position: relative;
				top: 1px;
				left: -2px;

				@include font-icons($icon-cross);
			}			
		}
	}

	&.alternate-color,
	.page-homepage.fp-viewing-1 & {
		.nav-link {
			color: $color-latham-red;

			&:after {
				background-color: $color-latham-red;
			}
		}

		.btn-mobile-menu,
		.btn-site-search {
			&:before {
				color: $color-latham-red;
			}
		}
	}

	&.alternate-bg {
		background-color: $color-white-white-smoke;
		border-bottom: 1px solid rgba($color-black, 0.1);
	}

	@include bp-max($bp-mobile-menu){

		.nav-links {
			display: none;
		}

		.btn-mobile-menu {
			display: block;
		}
	}

	@include bp-max($bp-between){
		top: $page-edge-size-between;
		left: $page-edge-size-between;
		right: $page-edge-size-between;
		padding: $page-edge-size-between*0.5;
		width: calc(100vw - #{$page-edge-size-between * 2});
	}

	@include bp-max($bp-mobile) {
		top: $page-edge-size-mobile;
		left: $page-edge-size-mobile;
		right: $page-edge-size-mobile;
		padding: $page-edge-size-mobile;
		width: calc(100vw - #{$page-edge-size-mobile * 2});
	}

	@include bp-max-height($bp-mobile) {
		top: $page-edge-size-mobile;
		left: $page-edge-size-mobile;
		right: $page-edge-size-mobile;
		padding: $page-edge-size-mobile * 0.5;
		width: calc(100vw - #{$page-edge-size-mobile * 2});

		.logo-img {
			width: px-to-em(125px);
		}
	}

	@include bp-max($bp-mobile){
		.logo-dark,
		.logo-white {
			width: px-to-em(125px);
		}
	}
}
