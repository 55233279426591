.circular-items-grid {
	$spacing: 1em;

	max-width: px-to-em(1000px);
	margin-left: auto;
	margin-right: auto;

	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	.circular-item-container {
		margin-bottom: $spacing;
	}

	@include bp-max(1190px){
		.circular-item-container {
			font-size: .9em;
		}
	}

	@include bp-max(1100px){
		.circular-item-container {
			font-size: 1em;
		}
	}

	@include bp-max(800px){
		.circular-item-container {
			font-size: .9em;
		}
	}

	@include bp-max(720px){
		.circular-item-container {
			font-size: 1em;
		}
	}

	@include bp-max($bp-mobile) {
		.circular-item-container {
			font-size: .9em;
		}
	}
}
