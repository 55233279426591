.document-card {
	$padding: 26px;

	&:hover {
		.btn-download {
			background-color: $color-gray-trout;
			color: $color-white;
		}
	}

	.document-name {
		$font-size: 14px;

		background-color: $color-latham-red;
		text-transform: uppercase;
		color: $color-white;
		padding: .5em px-to-em($padding);

		@include font-sans-serif-semibold($font-size);
	}

	.document-content {
		display: flex;
		background-color: $color-white;
		color: $color-gray-trout;
		padding: px-to-em($padding);
		align-items: center;
	}

	.rich-text {
		line-height: 1.25;

		ul {
			margin-top: 1em;
		}

		li {
			margin-top: 0;
		}
	}

	.document-type-icon {
		margin: 0 .5em;
		text-align: center;
	}

	.icon-image {
		max-width: 3.5em;
	}

	.btn-download {
		display: block;
		width: 100%;
		color: $color-gray-trout;
		text-transform: uppercase;
		border: 1px solid $color-gray-trout;
		padding: 1em;
		margin-top: 1em;
		transition: background-color $transition-duration-primary, color $transition-duration-primary;

		@include font-sans-serif-semibold(14px);

		&:after {
			@include font-icons($icon-download);
			position: relative;
			margin-left: 1em;
			font-size: 1.25em;
			top: 3px;
		}
	}
}
