.alpha-filter {
	$font-size: 22px;

	text-transform: uppercase;
	line-height: 1;
	color: $color-gray-aluminum;
	font-size: px-to-em($font-size);
	display: flex;
	align-items: center;

	@include font-sans-serif-semibold;

	.prompt {
		color: $color-black;
		margin-right: .5em;
		letter-spacing: 1px;
	}

	.current-option {
		display: none;
	}

	.filter-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;

		&:after {
			content: '|';
			order: 2;
		}
	}

	.filter-option {
		color: $color-gray-aluminum;
		transition: color $transition-duration-primary;
		order: 1;
		font-weight: inherit;

		&:last-of-type {
			order: 3;
		}

		&:hover {
			color: $color-latham-red;
		}
	}

	.is-active {
		font-size: px-to-em(32px, $font-size);
		color: $color-latham-red;
	}

	.has-no-entries {
		$color: $color-gray-silver;

		cursor: auto;
		color: $color;

		&:hover {
			color: $color;
		}
	}

	@include bp-max($bp-between) {
		$horizontal-spacing: 1em;
		$border-style: 1px solid $color-latham-red;
		display: block;
		position: relative;
		z-index: 1;

		.current-option {
			display: block;
			padding: .5em $horizontal-spacing;
			border: $border-style;
			background-color: $color-white;
			color: $color-latham-red;
			cursor: pointer;
			transition: background-color $transition-duration-primary;
			margin-top: 1em;

			&:hover {
				background-color: $color-gray-gainsboro;
			}

			&:after {
				@include font-icons($icon-angle-down);
				transition: transform $transition-duration-primary; 
				float: right;
			}
		}

		.filter-options {
			display: block;
			position: absolute;
			width: 100%;
			background-color: $color-white;
			border: $border-style;
			border-top: none;
			display: none;

			&:after {
				content: none;
			}
		}

		.filter-option {
			display: block;
			width: 100%;
			text-align: left;
			padding: .5em $horizontal-spacing;
			transition: background-color $transition-duration-primary;

			&:hover {
				color: $color-gray-aluminum;
				background-color: $color-gray-gainsboro;
			}
		}

		.is-active,
		.has-no-entries {
			display: none;
		}

		&.show-options {
			.current-option {
				&:after {
					transform: rotateX(180deg);
				}
			}

			.filter-options {
				display: block;
			}
		}
	}
}
