.btn-filter-label {
	text-transform: uppercase;
	color: $color-latham-red;
	letter-spacing: .75px;
	line-height: px-to-em(28px);
	transition: color $transition-duration-primary, border-color $transition-duration-primary;
	padding: .75em 2em;
	position: relative;
	border: 1px solid transparent;
	border-bottom: none;
	z-index: 2;
	background-color: $color-white;

	&:hover {
		color: $color-black;
	}

	&:after {
		@include font-icons($icon-angle-down);
		line-height: 1;
		font-size: 1.25em;
		margin-left: .5em;
		transition: transform $transition-duration-primary;
		display: inline-block;
	}

	&.no-arrow {
		&:after {
			content: none;
		}
	}

	&.is-selected {
		color: $color-black;
		border-color: $color-gray-gainsboro;

		&:after {
			transform: rotateX(180deg);
		}

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $color-white;
		}
	}

	@include bp-max($bp-between) {
		padding: .25em 1em;
	}

	@include bp-max(525px) {
		padding: .25em .5em;

		&:after {
			margin-left: .25em;
		}
	}

	@include bp-max(374px) {
		padding: .25em;
	}
}
