.resource-search-app {
	$section-spacing: px-to-em(80px);

	background-color: $color-white;
	position: relative;

	&:after {
		content: '';
		display: inline-block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($color-black, .7);
		opacity: 0;
		visibility: hidden;
		transition: opacity $transition-duration-primary, visibility 0.01s $transition-duration-primary;
	}

	&.filters-open {
		&:after {
			opacity: 1;
			visibility: visible;
			transition: opacity $transition-duration-primary .01s, visibility 0.01s;
		}
	}

	.search-criteria-list,
	.content-card-list {
		margin-top: $section-spacing;
	}

	@import 'filter-checkbox';
	@import 'search-filter-type';
	@import 'btn-filter-label';
	@import 'search-filters';
	@import 'search-criteria';
	@import 'search-criteria-list';

	.topic-details {
		margin-top: 2em;
	}

	.no-results-message {
		text-align: center;
	}

	.load-more-container {
		text-align: center;
		margin-top: 3em;
	}

	.btn-load-more {
		color: $color-latham-red;
		transition: color $transition-duration-primary;

		&:hover {
			color: $color-gray-trout;
		}
	}

	.back-to-top {
		text-align: right;
		max-width: px-to-em(1264px);
		margin: 3em auto 0;
	}

	.btn-back-to-top {
		color: $color-latham-red;
		transition: color $transition-duration-primary;
		margin-right: .5em;

		&:hover {
			color: $color-gray-trout;
		}
	}

	@include bp-max($bp-between){
		.search-criteria-list,
		.content-card-list {
			margin-top: px-to-em(40px);
		}
	}
}
